.space-card {
  display: flex;
  background: #ffffff;
  border: 0.14px solid #000000;
  box-sizing: border-box;
  box-shadow: 3px 8px 8px rgb(0 0 0 / 15%);
  border-radius: 5px;
  padding: 34px 41px;
  width: 70%;
  /* margin: 40px; */
  margin-top: 40px;
  margin-bottom: 40px;
  justify-content: space-between;
  position: relative;
}
.approval-status-name {
  /* margin-left: 34px; */
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
}
.space-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-content: center;
}

.space-resume-button {
  background: white;
  border: 1px solid #6568e6;
  box-sizing: border-box;
  border-radius: 4px;
  color: #6568e6;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* padding: 13px 137px; */
  width: 134px;
  height: 48px;
  margin: 20px 17px;
  cursor: pointer;
}
.space-submit-for-review-button {
  background: #6568e6;
  border: 1px solid #6568e6;
  box-sizing: border-box;
  border-radius: 4px;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* padding: 13px 137px; */
  width: 134px;
  height: 48px;
  margin: 20px 17px;
  cursor: pointer;
}
.space-card-message p {
  text-align: justify;
}
.space-card-message > img {
  height: 40px;
  width: 40px;
}
.space-card-message {
  background: #e6f0ff;
  border: 0.14px solid #f1f1f1;
  border-radius: 5px;
  padding: 30px;
  display: grid;
  grid-template-columns: 1fr 9fr;
  margin-top: 48px;
  /* width: 811px; */
  /* margin-left: -197px; */
}
.space-delete-button {
  cursor: pointer;
  border: none;
}
.space-show-space-cart-manu-button > img {
  width: 15px;
}
.space-show-space-cart-manu li:last-child {
  border: none;
}
.space-show-space-cart-manu li {
  background-color: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  height: 52.5px;
  width: 107px;
  border-bottom: 0.25px solid #bababa;
  padding-left: 14px;
  color: #757575;
}
.space-show-space-cart-manu ul {
  list-style: none;
  background-color: white;
  list-style: none;
  margin-left: 0;
  padding-left: 0;
  width: 100%;
  background: white;
}
.space-show-space-cart-manu {
  position: absolute;
  width: 60px;
  background: white;
  margin-top: 10px;
  /* border: 0.25px solid #656369 !important; */
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  height: 107px;
  width: 108px;
  z-index: 2;
}
.space-show-space-cart-manu-button {
  border-radius: 100%;
  height: 22px;
  width: 22px;
  background: #f7f7f7;
  border: 0.25px solid #656369;
  box-shadow: 0.5px 0.8px 0.8px rgba(0, 0, 0, 0.25);
}
.space-content h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  /* width: 400px; */
}
.space-content {
  width: 443px;
  margin-left: 41px;
}

.pace-content-progress-bar {
  margin-top: 24px;
}

.delete-box-wrapper {
  background-color: #0000007d;
  position: absolute;
  border: 0;
  left: 15.5%;
  top: 12.7%;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  bottom: 0;
}
.delete-box-card {
  width: 370px;
  height: 370px;
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background: #ffffff;
  border: 0.25px solid #656369;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 4px;
  position: relative;
  z-index: 100;
}
.delete-box-card-close-button {
  border: none;
  color: #c4c4c4;
  font-size: 25px;
  position: absolute;
  top: 0;
  right: 14px;
}
.delete-box-card-body p,
.delete-box-card-body h4 {
  text-align: center;
}
.delete-box-card-body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
  flex-direction: column;
  align-content: center;
}
.delete-box-card-header {
  display: grid;
  grid-template-columns: 11fr 1fr;
  padding: 10px;
}
.delete-box-card-footer button:nth-child(1) {
  background-color: #6568e6;
  color: white;
}
.delete-box-card-footer button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* padding: 13px 137px; */
  width: 108px;
  height: 38px;
  background: #ffffff;
  /* Dark-Gray_Secondary-Text */
  border: 0.25px solid #6568e6;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}
.delete-box-card-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  gap: 10px;
  padding: 20px;
}

.space-card-basic-information {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 21px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  gap: 10px;
}

.max-booking-input-group input {
  padding: 12px;
  border: 1px solid #b4b4b4;
  border-radius: 4px;
  font-size: 16px;
  width: 200px;
}
.max-booking-input-group label {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
}
.max-booking-input-group {
  margin-top: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}
