.space-setup-availability-inserte-form {
}
.space-setup-availability-inserte-form hr {
  margin-bottom: 37px;
  width: 513px;
  border: 1px solid #bababa;
  margin-top: 56px;
}
.availability-inserte-list {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 860px;
}
.availability-inserte-list > li {
  border-bottom: 1px solid #bababa;
  padding-bottom: 42px;
  margin-bottom: 39px;
}
.deletebutton {
  cursor: pointer;
  margin-top: 6px;
}
.plusbutton {
  cursor: pointer;
  margin-top: 7px;
}
.availability-inserte-list p {
  text-align: center;
}
.availability-inserte-list li {
  display: flex;
  gap: 10px;
  align-items: flex-start;
  margin-bottom: 24px;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
}

.time-range {
  display: flex;
  align-items: center;
  grid-gap: 15px;
}

.day {
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  width: 120px;
}

.availability-inserte-list select {
  padding: 10px;
  border: none;
  overflow: hidden;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background: url('./../img/polygon_2.png') 96% / 10% no-repeat #e8e8e8;
  background-position-x: 97px;
  background-position-y: 16px;
  color: #959595;
  padding-left: 22px;
  width: 120px;
}
