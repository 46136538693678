.inquiry-card-list-wrapper {
  padding: 50px;
  padding-right: 110px;
}
.single-inquiry-card {
  display: grid;
  grid-template-columns: 1fr 1fr 5fr 1fr;
  padding: 25px;
  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 5px;
  /* margin: 22px; */
  /* width: 1042px; */
  height: 176px;
  margin-bottom: 13px;
}
.inquiry-card-property-image {
}
.inquiry-card-property-image img {
  width: 186.91px;
  height: 125.03px;
  border-radius: 8px;
}
.inquiry-card-guest-image {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.inquiry-card-guest-image img {
  width: 60px !important;
  height: 60px !important;
  border: 3px solid #808080;
  border-radius: 100%;
}
.inquiry-card-inquiry-information {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: flex-start;
  gap: 10px;
}
.inquiry-card-inquiry-information-date-time {
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 19px !important;
}
.inquiry-card-inquiry-information-last-message {
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 19px !important;
  color: #656369 !important;
}
.inquiry-card-inquiry-information-last-message-date {
  font-size: 16px;
  line-height: 19px;
  color: #b4b4b4;
}
.inquiry-card-inquiry-status-amount {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: flex-start;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #0d0119;
}
.inquiry-card-list-pagination-buttons div {
  cursor: pointer;
  border: 1px solid black;
  padding: 10px;
  border-radius: 4px;
}
.inquiry-card-list-pagination-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  margin-right: 30px;
}
.inquiry-card-inquiry-status {
}
.inquiry-card-inquiry-amount {
}

.conversation-history-wrapper {
  display: grid;
  grid-template-columns: 6fr 2fr;
  gap: 60px;
}

.conversation-history-inquiry-information-wrapper {
  font-size: 16px !important;
}
.conversation-history-inquiry-information {
  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 8px;
}
.conversation-history-inquiry-property-image h4 {
  position: absolute;
  top: 10px;
  left: 10px;
  color: white;
}
.conversation-history-inquiry-property-image img {
  height: 276px;
}
.conversation-history-inquiry-property-image {
  position: relative;
}
.conversation-history-inquiry-host-information h4 {
  margin-left: 32px;
}
.conversation-history-inquiry-host-information {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 32px;
  align-content: center;
  height: 144px;
  border-bottom: 1px solid #cccccc;
}
.conversation-history-inquiry-host-information img {
  height: 80px;
  width: 80px;
  border-radius: 100%;
}
.conversation-history-inquiry-property-address {
  padding-left: 32px;
  padding-top: 32px;
}
.conversation-history-inquiry-reservation-date-time {
  padding-left: 32px;
  padding-top: 32px;
}
.conversation-history-inquiry-reservation-date > img,
.conversation-history-inquiry-reservation-time > img {
  height: 13px;
  width: 13px;
}
.conversation-history-inquiry-reservation-date > div,
.conversation-history-inquiry-reservation-time > div,
.conversation-history-inquiry-expected-attendies > div > div {
  margin-left: 20px;
}
.conversation-history-inquiry-reservation-date,
.conversation-history-inquiry-reservation-time,
.conversation-history-inquiry-expected-attendies > div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
}

.conversation-history-inquiry-reservation-time {
}
.conversation-history-inquiry-reservation-activity {
  padding-left: 32px;
  padding-top: 32px;
}
.conversation-history-inquiry-expected-attendies {
  padding-left: 32px;
  padding-top: 32px;
}
.conversation-history-inquiry-alcohol-consumption {
  padding-left: 32px;
  padding-top: 32px;
}
.conversation-history-inquiry-price-details {
  padding-left: 32px;
  /* padding-right: 32px; */
  padding-right: 1.5em;
  padding-top: 32px;
  padding-bottom: 64px;
}
.conversation-history-inquiry-price-calculation {
  display: grid;
  grid-template-columns: 5fr 1fr;
}
.conversation-history-inquiry-information-worning {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  border: 1px solid #b4b4b4;
  border-radius: 8px;
  margin-top: 37px;
  padding: 32px;
}

.conversation-history {
}
.conversation-history-other-information {
  height: 280px;
  border-bottom: 2px solid #d9d9d9;
  margin-bottom: 62px;
}
.conversation-history-other-information h1 {
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;
  color: #0d0119;
}
.conversation-history-other-information p {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
}
.conversation-history-back-button img {
  margin-right: 10px;
}
.conversation-history-back-button {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #6568e6;
  text-transform: capitalize;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  cursor: pointer;
  display: inline;
}

.group-of-message-information {
  display: flex;
  gap: 27px;
  border-bottom: 2px solid #d9d9d9;
  padding: 40px;
  margin-bottom: 35px;
}
.message-user-image img {
  height: 52px;
  width: 52px;
  border-radius: 100%;
}
.message-data {
}
.message-name {
  display: flex;
  gap: 10px;
}
.senders-name {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}
.message-date {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #b4b4b4;
}
.conversation-list {
  max-height: 700px;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 50px;
}

.send-message-host-image img {
  height: 65px;
  width: 65px;
  border-radius: 100%;
}

.send-message-input-group {
  display: flex;
  flex-direction: column;
  align-content: flex-end;
  align-items: flex-end;
  width: 100%;
  padding-right: 54px;
}
.send-message-input-group textarea {
  width: 100%;
  height: 226px;
  background: #f7f7f7;
  border-radius: 8px;
  border: none;
  padding: 20px;
}
.send-message-form button {
  width: 134px;
  height: 48px;
  background: #6568e6;
  border-radius: 4px;
  color: white;
  margin-top: 20px;
}
.send-message-form {
  display: flex;
  gap: 26px;
  padding-left: 94px;
}
