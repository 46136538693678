.loading-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    /* padding: 10px; */
}
.loading {
    padding: 8px;
    min-width: 122px;
    position: relative;
    display: inline-block;
    background: conic-gradient(
      from var(--angle),
      #4A90E2 0deg 90deg,
      #EFF1F4 90deg 360deg
    );
    border-radius: 3px;
    animation: 3s rotate linear infinite;
    color: rgba(55, 57, 76, 0.3);
    z-index: 1;
    font-family: sans-serif;
    font-size: 12px;
}
  
.loading::before {
    content: "";
    background: #FFFFFF;
    border-radius: 2px; /* 1px smaller */
    position: absolute;
    top: 1px;
    bottom: 1px;
    left: 1px;
    right: 1px;
    z-index: -1;
}
  
@keyframes rotate {
    to {
      --angle: 360deg;
    }
}
  
@property --angle {
    syntax: '<angle>';
    initial-value: 0deg;
    inherits: false;
}


/*use flexbox to centered element*/
div.hr-loading-wrapper {
    width: 100%;
    /* min-height: 100vh; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
}
div.loading-bar {
    width: 100px;
    height: 2px;
    background-color: #d6cec2;
    border-radius: 10px;
    margin-top: 19px;
    overflow: hidden;
    position: relative;
}
div.loading-bar::after {
    content: '';
    width: 50px;
    height: 2px;
    position: absolute;
    background-color: #0074b4;
    transform: translateX(-20px);
    animation: loop 2s ease infinite;
}
@keyframes loop {
    0%,100% {
      transform: translateX(-28px);
    }
    50% {
      transform: translateX(78px)
    }
}

.time-loading {
    position: absolute !important;
    width: 10px !important;
    left: 47rem !important;
    top: 39rem !important;
}