.policy-and-ruls-of-space-insert-space-form{}

.policy-and-ruls-of-space-insert-lasr-hr{
    width: 1035px;
}

.policy-and-ruls-of-space-insert-space-button-group {
    width: 1035px;
    margin-top: 78px;
    display: flex;
    align-content: center;
    justify-content: flex-end;
    align-items: flex-start;
}
.policy-and-ruls-of-space-insert-space-button-group > button,
.buffer-time-ruls > button,
.booking-window-rules > button {
    background: #6568E6;
    color: white;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 4px;
    width: 134px;
    height: 48px;
}

.policy-and-ruls-of-space-insert-space-form .radio-button:first-child {
    margin-top: 0px;
}
.policy-and-ruls-of-space-insert-space-form .radio-button {
    margin-top: 47px;
}

.buffer-time-ruls h1,.booking-window-rules h1{
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    margin-top: 78px;
    margin-bottom: 22px;
}
.buffer-time-ruls p,.booking-window-rules p{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
}
.buffer-time-ruls > button,.booking-window-rules > button{
    background: #6568E6;
    margin-right: 172px;
    color: white;
    width: 231px;
    height: 48px;
    margin-top: 51px;
    margin-bottom: 100px;
}
.backButton{
    background-color: #FFFFFF;
}
.buffer-time-ruls{}
.booking-window-rules{}


.buffer-time-ruls textarea{
    background-color: #F3F3F3;
    width: 503px;
    height: 350px;
    border: none;
    padding: 17px 51px 0px 13px;
}

.policy-and-ruls-input-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.policy-and-ruls-input-group input {
    background-color: #F3F3F3;
    border: none;
    width: 552px;
    height: 35px;
    margin-bottom: 29px;
    padding: 0px 10px;
}
.policy-and-ruls-input-group label {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
}

.check-box-group-list:last-child {
    margin-bottom: 103px;
}
.check-box-group-list h1{
    margin-top: 83px;
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
}
.check-boxes {}
.check-box-group label{
    margin-left: 7px;
}
.check-box-group input{}

.buffer-time-ruls ul li{
    list-style: none;
    text-align: justify;
    width: 1100px;
    margin-top: 20px;
}

.buffer-time-ruls ul li ul li{
    list-style: disc;
}

.list-content p{
    margin-top: 15px;
}
.list-content {
    margin-left: 36px;
}

.buffer-time-ruls-input-box {
    position: relative;
    width: 885px;
    height: 90px;
    margin-top: 39px;
}
.delete-buffer-time-ruls-input-box-button {
    background-color: #F3F3F3;
    color: red;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    height: 20px;
    width: 20px;
    border: 1px solid black;
    border-radius: 100%;
    text-align: center;
    right: -109px;
    top: 4px;
}

.buffer-time-ruls-content-wrapper {
    background: #C7C7C7;
    border-radius: 4px;
    width: 1000px;
    height: 90px;
    padding: 33px 22px;
}

.custom-check-box{
    font-size: 19px;
    /* margin-right: 10px; */
    border: 1px solid lightgray;
    height: 15px;
    text-align: center;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    padding: 0px;
    color: white;
}

.selected-check-box {
    background: black;
}


.drop-down-box label {
    margin-left: 10px;
    display: flex;
    align-content: center;
    align-items: center;
}
.drop-down-box{
    margin: 0px 27px 0px 27px;
    padding: 12px 34px;
    color: #959595;
    background-color: #F3F3F3;
    border: none;
}

.polygon1{
    height: 10px;
    width: 15px;
    margin: 0px 0px 4px 13px;
}

.drop-down {
    /* position: absolute; */
}
.drop-down-box {
    cursor: pointer;
}

.space-drop-down {
    position: absolute;
    left: 20px;
}
.time-drop-down input{
    margin-right: 10px;
}
.time-drop-down {
    position: absolute;
    right: 440px;
    top: 78px;
}
.drop-down ul li{
    margin-left: 0;
    padding: 10px 10px 5px 25px ;
    /* cursor: pointer; */
}
.drop-down ul{
    margin: 0;
    width: 260px;
    max-height: 399px;
    position: relative;
    background: #FFFFFF;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 4px;
    z-index: 100;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 11px 0px 13px 1px;
}
.space-drop-down ul{
    left: 52px;
    top: 24px;
}


.selectedTime {
    position: relative;
    display: block;
    background: rgba(196, 196, 196, 0.25);
    color: darkcyan;
}
.tikMark {
    position: absolute;
    left: 6px;
}

.role-delete-confirm-box {
    position: absolute;
    right: -150px;
    top: -5px;
    background: white;
    height: 49px;
    width: 128px;
    border: 1px solid;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    cursor: pointer;
}

.blocked-date-section p{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
}
.blocked-date-section h1{
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    margin-top: 78px;
    margin-bottom: 22px
}
.blocked-date-section > button{
    background: #6568E6;
    margin-right: 172px;
    color: white;
    width: 231px;
    height: 48px;
    margin-top: 51px;
    margin-bottom: 100px;
}
.single-blocked-date-wrapper{
    position: relative;
    width: 885px;
    height: 90px;
    margin-top: 39px;
}
.single-blocked-date input{
    margin: 0px 27px 0px 27px;
    padding: 8px 12px;
    color: #959595;
    background-color: #F3F3F3;
    border: none;
    margin-top: -6px;
}
.single-blocked-date{
    background: #C7C7C7;
    border-radius: 4px;
    width: 1000px;
    height: 90px;
    padding: 33px 22px;
}
.property-list{
    margin: 0px 27px 0px 27px;
    padding: 12px 34px;
    color: #959595;
    background-color: #F3F3F3;
    border: none;
}
.property-list-for-select ul li > input{
    padding: 0px;
    margin: 0px;
}
.property-list-for-select ul li > label{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    align-content: center;
    gap: 10px;
    margin-left: 10px;
}
.property-list-for-select ul li{
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    align-content: center;
}
.property-list-for-select ul{
    list-style: none;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: 100%;
    align-content: flex-start;
}
.property-list-for-select {
    position: absolute;
    background: white;
    padding: 10px;
    z-index: 1;
    overflow-y: scroll;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    width: 244px;
    top: 84px;
    left: 37rem;
    border: 1px solid black;
}
.fake-chake-box-blocked-date{
    border: 1px solid lightgray;
    height: 15px;
    width: 15px;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
}
.delete-blocked-date-confirm-button {
    position: absolute;
    left: 37px;
    border: 1px solid black;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
}
.delete-blocked-date {
    position: absolute;
    right: -109px;
    top: 8px;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 100%;
    color: red;
    border: 1px solid black;
}
.blocked-date-section {}