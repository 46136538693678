.export-button {
  background: white;
  /* position: absolute; */
  /* right: 100px; */
  /* top: 45rem; */
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
  /* align-content: center; */
  /* flex-direction: column; */
  border: 1px solid black;
  border-radius: 6px;
  color: black;
  margin-left: 0px;
}
.export-button-drop-down {
  position: absolute;
  width: 130px;
  background: white;
  border: 1px solid black;
  border-radius: 6px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  padding: 16px;
  top: 40px;
  z-index: 2;
}
