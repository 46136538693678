.file-upload-group {
}
.file-upload-group h1 {
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
}

.photo-upload-placeholder {
  cursor: pointer;
}

.file-upload-group p {
  font-size: 16px;
  line-height: 24px;
  width: 426px;
  margin-top: 8px;
  margin-bottom: 26px;
}

.file-upload-group input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
}

.space-photo-insert-form hr {
  margin-bottom: 37px;
  width: 513px;
  border: 1px solid #bababa;
  margin-top: 56px;
}
.photo_upload_box {
  position: relative;
  background-color: #ededed;
  height: 175px;
  width: 795px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.photo_upload_box p {
  font-weight: 500;
  font-size: 17px;
  line-height: 21px;
  text-align: center;
  color: #7a7a7a;
}

.file_upload_button {
  position: relative;
  cursor: pointer;
  width: 335px;
  height: 44px;
  background-color: #fff;
  display: flex;
  align-items: center;
}

.photo-upload-place-holder-wrapper {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.116);
  top: 11rem;
  bottom: -132vw;
  left: 15.4vw;
  right: 0vw;
  height: 140vh;
  /* display: flex; */
  /* align-content: center; */
  /* justify-content: center; */
  z-index: 10;
}
.thumbnail-message {
  background: white;
  width: 139px;
  font-size: 16px;
  position: absolute;
  top: 10px;
  left: 15px;
  border-radius: 10px;
}
.photo-upload-box div > img {
  width: 160px;
  height: 90px;
}
.photo-upload-box div {
  width: 160px;
  height: 90px;
  /* width: 100%; */
  /* background: #E6F0FE; */
}
.delete-box-message {
}
.photo-upload-place-holder > .close-button {
  /* position: absolute; */
  /* right: 14px; */
  /* top: 13px; */
  font-size: 15px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 14px; */
}
.photo-upload-place-holder {
  position: relative;
  width: 710px;
  /* height: 603px; */
  background: #ffffff;
  border-radius: 4px;
  margin-top: 8vw;
  margin-left: 18vw;
  padding: 40px;
}
.photo-upload-box {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 37px;
  margin: 58px 64px 0px 94px;

  padding-bottom: 62px;
}
.photo-upload-box-button div:nth-child(2) {
  background: #e6f0fe;
  border: 0.5px solid #000000;
  width: 160px;
  height: 44px;
  margin-right: 0px;
}
.photo-upload-box-button div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 243px;
  height: 44px;
  margin-top: 27px;
  /* margin-bottom: 49px; */
  background: #ffffff;
  border: 0.5px solid #000000;
  box-sizing: border-box;
  border-radius: 4px;
  margin-right: 20px;
}
.photo-upload-box-button {
  display: flex;
  align-items: center;
  border-top: 1px solid gainsboro;
  justify-content: flex-end;
  /* margin-right: 62px; */
}
/* .space-setup-inserte-section{
    position: relative;

} */

.upload-photo-button-side-image,
.upload-done-button-side-image {
  margin-bottom: 4px;
  margin-right: 10px;
  height: 25px;
  width: 25px;
}
.photo-upload-box-button div {
  cursor: pointer;
}
.upload-done-button-side-image {
}
.uploaded-picture-visualisation > div {
  background-color: rgba(255, 255, 255, 0.8);
  text-align: center;
  padding: 0px 0;
  font-size: 30px;
  /* background-color: #e6f0fe; */
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 90px;
  /* height: auto; */
  /* height: 100%; */
  /* border: 1px solid red; */
}

.draggable-cursore {
  cursor: url('https://ssl.gstatic.com/ui/v1/icons/mail/images/2/openhand.cur'),
    default !important;
}

.draggable-cursore:active {
  cursor: url('https://ssl.gstatic.com/ui/v1/icons/mail/images/2/closedhand.cur'),
    default !important;
}

.uploaded-picture-visualisation-title {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  margin-top: 126px;
  margin-bottom: 20px;
}
.uploaded-picture > .dotted-button > span {
  background-color: #000000;
  width: 2.86px;
  height: 2.86px;
  border-radius: 100%;
}

.image-manu > p {
  cursor: pointer;
}
.image-manu {
  position: absolute;
  top: 20px;
  height: 74px;
  width: 66px;
  right: 7px;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
  border: 1px solid #000000;
  border-radius: 4px;
}

.uploaded-picture > .dotted-button {
  position: absolute;
  background: #eaebec !important;
  box-shadow: 0.5px 0.8px 0.8px rgba(0, 0, 0, 0.25);
  width: 20px;
  height: 20px;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;
  gap: 2px;
  border-radius: 100%;
  right: 5px;
  top: 5px;
  cursor: pointer;
  border: none;
}
.uploaded-picture {
  position: relative;
}
.uploaded-picture:first-child {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 3;
  width: 100%;
  min-height: 200px;
}
.uploaded-picture-visualisation {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 15px;
  padding: 10px;
  width: 40%;
}
