.btn__1 {
    display: flex;
    border-radius: 4px;
    background-color: #6568e6;
    width: 260px;
    height: 48px;
    align-items: center;
    justify-content: center;
  }
  .btn__1:hover {
    background-color: #6567e6e6;
    opacity: 0.8;
  }
  .btn__1 label {
    color: #fff;
    font-weight: 450;
    font-size: 16px;
  }