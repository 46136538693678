.w-layout-grid {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  grid-row-gap: 16px;
  grid-column-gap: 16px;
}

.w-form-formradioinput--inputType-custom {
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-top-color: #ccc;
  border-bottom-color: #ccc;
  border-left-color: #ccc;
  border-right-color: #ccc;
  border-top-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-right-style: solid;
  width: 12px;
  height: 12px;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
}

.w-form-formradioinput--inputType-custom.w--redirected-focus {
  box-shadow: 0px 0px 3px 1px #3898ec;
}

.w-form-formradioinput--inputType-custom.w--redirected-checked {
  border-top-width: 4px;
  border-bottom-width: 4px;
  border-left-width: 4px;
  border-right-width: 4px;
  border-top-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  border-right-color: #3898ec;
}

.w-checkbox {
  display: block;
  margin-bottom: 5px;
  padding-left: 20px;
}

.w-checkbox::before {
  content: ' ';
  display: table;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
}

.w-checkbox::after {
  content: ' ';
  display: table;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  clear: both;
}

.w-checkbox-input {
  float: left;
  margin-bottom: 0px;
  margin-left: -20px;
  margin-right: 0px;
  margin-top: 4px;
  line-height: normal;
}

.w-checkbox-input--inputType-custom {
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-top-color: #ccc;
  border-bottom-color: #ccc;
  border-left-color: #ccc;
  border-right-color: #ccc;
  border-top-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-right-style: solid;
  width: 12px;
  height: 12px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.w-checkbox-input--inputType-custom.w--redirected-checked {
  background-color: #3898ec;
  border-top-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  border-right-color: #3898ec;
  background-image: url('https://d3e54v103j8qbb.cloudfront.net/static/custom-checkbox-checkmark.589d534424.svg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.w-checkbox-input--inputType-custom.w--redirected-focus {
  box-shadow: 0px 0px 3px 1px #3898ec;
}

/* h3 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 24px;
  line-height: 30px;
  font-weight: bold;
}

li {
  font-family: Inter, sans-serif;
}

img {
  display: inline-block;
  max-width: 100%;
}

figcaption {
  margin-top: 5px;
  font-family: Inter, sans-serif;
  text-align: center;
} */

.sticky-nav {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 99;
  padding: 20px;
  background-color: #f8f8f8;
  box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.25);
}

.nav-grid {
  display: -ms-grid;
  display: grid;
  width: 100%;
  margin: 0px;
  padding: 0px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-flow: column;
  grid-auto-columns: -webkit-max-content;
  grid-auto-columns: max-content;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  -ms-grid-columns: 0.5fr minmax(-webkit-max-content, 1.25fr) -webkit-max-content -webkit-max-content;
  -ms-grid-columns: 0.5fr minmax(max-content, 1.25fr) max-content max-content;
  grid-template-columns: 0.5fr minmax(-webkit-max-content, 1.25fr) -webkit-max-content -webkit-max-content;
  grid-template-columns: 0.5fr minmax(max-content, 1.25fr) max-content max-content;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.nav-logo-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 60px;
  margin-right: 0px;
  margin-left: 0px;
  padding: 0px 15px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.nav-logo {
  width: auto;
  height: 50%;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: 0% 50%;
  object-position: 0% 50%;
}

.nav-link {
  display: block;
  margin-right: 0px;
  margin-left: 0px;
  padding: 10px 0px;
  color: #444;
  font-weight: 500;
  text-decoration: none;
}

.nav-link:hover {
  text-decoration: underline;
}

.nav-link.log-in {
  padding-right: 15px;
}

.search-bar {
  width: auto;
  height: 100%;
  text-align: center;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: 0% 50%;
  object-position: 0% 50%;
}

.form-success {
  padding: 32px;
  border-radius: 6px;
  background-color: #d4e9ff;
  color: #444;
  font-size: 16px;
  line-height: 1.4em;
}

.text-input {
  margin-bottom: 16px;
  padding: 24px 16px;
  border-style: solid;
  border-width: 1px;
  border-color: #e1e5e7;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.01);
  font-size: 15px;
  line-height: 1.4px;
}

.text-input:focus {
  border-color: #529dff;
}

.text-input::-webkit-input-placeholder {
  color: #e1e5e7;
}

.text-input:-ms-input-placeholder {
  color: #e1e5e7;
}

.text-input::-ms-input-placeholder {
  color: #e1e5e7;
}

.text-input::placeholder {
  color: #e1e5e7;
}

.text-input.form {
  min-height: 120px;
}

.sg-label {
  margin-top: 32px;
  color: #808c91;
  font-size: 14px;
  line-height: 1.2em;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.form-radio-button {
  margin-bottom: 16px;
  padding-left: 24px;
}

.button {
  margin-bottom: 8px;
  padding: 8px 32px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 6px;
  background-color: #356af0;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.03);
  -webkit-transition: border-color 200ms ease, color 200ms ease,
    box-shadow 200ms ease, background-color 200ms ease;
  transition: border-color 200ms ease, color 200ms ease, box-shadow 200ms ease,
    background-color 200ms ease;
  color: #fff;
  font-size: 18px;
  line-height: 1.5;
  font-weight: 500;
  text-align: center;
}

.button:hover {
  background-color: #0f42c3;
  box-shadow: 0 7px 20px -10px rgba(0, 0, 0, 0.2);
}

.button:active {
  background-color: #0a3092;
}

.button.disabled {
  background-color: #d4e9ff;
  box-shadow: none;
}

.button.disabled:hover {
  color: #fff;
}

.button.grey {
  background-color: #e1e5e7;
  box-shadow: none;
  color: #4c5458;
}

.button.grey:hover {
  background-color: #afb7bb;
  color: #2a2f31;
}

.button.grey:active {
  background-color: #808c91;
  color: #111314;
}

.button.minimal {
  margin-right: 8px;
  margin-left: 8px;
  padding-right: 16px;
  padding-left: 16px;
  background-color: transparent;
  box-shadow: none;
  color: #356af0;
}

.button.minimal:hover {
  color: #0f42c3;
}

.button.minimal:active {
  color: #0a3092;
}

.button.outline {
  background-color: transparent;
  box-shadow: inset 0 0 0 2px #529dff;
  color: #356af0;
}

.button.outline:hover {
  background-color: #356af0;
  box-shadow: inset 0 0 0 2px #356af0;
  color: #fff;
}

.button.outline:active {
  background-color: #0f42c3;
  box-shadow: inset 0 0 0 2px #0f42c3;
}

.button.light {
  background-color: #d0e8ff;
  color: #356af0;
}

.button.light:hover {
  background-color: #9ec5fa;
  color: #0f42c3;
}

.button.light:active {
  background-color: #356af0;
  color: #fff;
}

.grid {
  position: relative;
  display: -ms-grid;
  display: grid;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  grid-auto-columns: 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.grid.desk-3 {
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
}

.grid.desk-4 {
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.paragraph {
  margin-bottom: 16px;
  font-family: Inter, sans-serif;
  line-height: 1.5em;
}

.paragraph.small {
  opacity: 0.75;
  font-size: 14px;
}

.paragraph.large {
  font-family: Inter, sans-serif;
  font-size: 20px;
}

.field-label {
  color: #4c5458;
  font-size: 14px;
  font-weight: 500;
}

.sg-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 100%;
  margin-left: 240px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.wrapper {
  width: 100%;
  min-height: 100vh;
}

.vertical-tab {
  width: 100%;
  margin-top: 24px;
  margin-bottom: 24px;
}

.spacer {
  height: 48px;
  background-color: #ece7e0;
}

.spacer._80 {
  width: 80px;
  height: 80px;
  background-color: transparent;
}

.spacer._16 {
  width: 16px;
  height: 16px;
  background-color: transparent;
}

.spacer._48 {
  width: 48px;
  border-radius: 6px;
  background-color: transparent;
}

.spacer._32 {
  width: 32px;
  height: 32px;
  border-radius: 6px;
  background-color: transparent;
}

.spacer._128 {
  width: 128px;
  height: 128px;
  background-color: transparent;
}

.spacer._24 {
  width: 24px;
  height: 24px;
  background-color: transparent;
  text-transform: uppercase;
}

.spacer._64 {
  width: 64px;
  height: 64px;
  background-color: transparent;
}

.spacer._96 {
  width: 96px;
  height: 96px;
  background-color: transparent;
}

._12-columns {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: -16px;
  margin-left: -16px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
}

._12-columns.align-left {
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.ds-colour-block {
  display: inline-block;
  width: 12.5%;
  padding-right: 16px;
}

.form-dropdown {
  position: relative;
  width: 100%;
  height: 48px;
  margin-bottom: 16px;
  padding: 8px 16px;
  border-style: solid;
  border-width: 1px;
  border-color: #e1e5e7;
  border-radius: 5px;
  background-color: #fff;
  color: #4c5458;
  font-size: 15px;
}

.form-dropdown:hover {
  color: #37474e;
}

.form-dropdown:focus {
  color: #37474e;
}

.form-dropdown::-webkit-input-placeholder {
  color: #999;
  font-size: 15px;
}

.form-dropdown:-ms-input-placeholder {
  color: #999;
  font-size: 15px;
}

.form-dropdown::-ms-input-placeholder {
  color: #999;
  font-size: 15px;
}

.form-dropdown::placeholder {
  color: #999;
  font-size: 15px;
}

.tabs-menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-bottom: 48px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

._1-2-grid {
  position: relative;
  display: -ms-grid;
  display: grid;
  max-width: 1000px;
  margin-right: auto;
  margin-bottom: 24px;
  margin-left: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  grid-auto-columns: 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  -ms-grid-columns: 1fr 2fr;
  grid-template-columns: 1fr 2fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.color-block {
  width: 100%;
  height: 60px;
  margin-right: 0px;
  margin-bottom: 10px;
  margin-left: 0px;
  border-radius: 5px;
  background-color: #111314;
}

.color-block.light-grey {
  background-color: #808c91;
}

.color-block.back-blue {
  background-color: #e9f3fd;
}

.color-block.dark-blue {
  background-color: #0f42c3;
}

.color-block.soft-grey {
  background-color: #afb7bb;
}

.color-block.soft-blue {
  background-color: #9ec5fa;
}

.color-block.back-grey {
  background-color: #f0f2f3;
}

.color-block.light-blue {
  border-style: none;
  background-color: #529dff;
}

.color-block.pale-blue {
  background-color: #d0e8ff;
}

.color-block.dark-grey {
  background-color: #2a2f31;
}

.color-block.hard-blue {
  background-color: #0a3092;
}

.color-block.blue {
  background-color: #356af0;
}

.color-block.silver {
  background-color: #e1e5e7;
}

.color-block.white {
  border-style: solid;
  border-width: 1px;
  border-color: #f0f2f3;
  background-color: #fff;
}

.color-block.grey {
  background-color: #4c5458;
}

.small-button {
  margin-bottom: 16px;
  padding: 6px 16px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 4px;
  background-color: #356af0;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.03);
  -webkit-transition: border-color 200ms ease, color 200ms ease,
    box-shadow 200ms ease, background-color 200ms ease;
  transition: border-color 200ms ease, color 200ms ease, box-shadow 200ms ease,
    background-color 200ms ease;
  color: #fff;
  font-size: 15px;
  line-height: 1.5;
  font-weight: 500;
  text-align: center;
}

.small-button:hover {
  background-color: #0f42c3;
  box-shadow: 0 7px 20px -10px rgba(0, 0, 0, 0.2);
  color: #fff;
}

.small-button:active {
  background-color: #0a3092;
}

.small-button.light {
  margin-right: 0px;
  background-color: #d0e8ff;
  color: #356af0;
}

.small-button.light:hover {
  background-color: #9ec5fa;
  color: #0f42c3;
}

.small-button.light:active {
  background-color: #356af0;
  color: #fff;
}

.small-button.outline {
  background-color: transparent;
  box-shadow: inset 0 0 0 2px #9accff;
  color: #356af0;
}

.small-button.outline:hover {
  background-color: #356af0;
  box-shadow: inset 0 0 0 2px #356af0;
  color: #fff;
}

.small-button.outline:active {
  background-color: #0f42c3;
  box-shadow: inset 0 0 0 2px #0f42c3;
}

.sg-block {
  margin-bottom: 32px;
}

.ds-icon {
  display: inline-block;
  margin-right: 24px;
  margin-bottom: 4px;
}

.heading.h1 {
  margin-bottom: 18px;
  font-family: Inter, sans-serif;
  font-size: 45px;
  line-height: 1.25em;
}

.heading.h3 {
  font-family: Inter, sans-serif;
  font-size: 28px;
  line-height: 1.4em;
}

.heading.h2 {
  margin-bottom: 20px;
  font-family: Inter, sans-serif;
  font-size: 36px;
  line-height: 1.3em;
}

.heading.h4 {
  margin-bottom: 12px;
  font-family: Inter, sans-serif;
  font-size: 22px;
  line-height: 1.4em;
  font-weight: 600;
}

.heading.h6 {
  font-family: Inter, sans-serif;
  color: #4c5458;
  font-size: 13px;
  line-height: 1.5em;
  font-weight: 500;
}

.heading.h5 {
  margin-bottom: 12px;
  font-family: Inter, sans-serif;
  color: #4c5458;
  font-size: 15px;
  line-height: 1.5em;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.form-error {
  padding: 16px;
  border-radius: 6px;
  background-color: #ece7e0;
  color: #444;
}

.ds-module {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 100px;
  min-width: 100px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border-radius: 5px;
  background-color: #d0e8ff;
}

.tab-link {
  width: 140px;
  margin-right: 0px;
  margin-left: 0px;
  padding: 15px 20px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border-bottom: 2px solid #afb7bb;
  background-color: transparent;
  opacity: 0.5;
  -webkit-transition: border-color 200ms ease, color 200ms ease;
  transition: border-color 200ms ease, color 200ms ease;
  color: #4c5458;
  font-size: 18px;
  text-align: center;
}

.tab-link:hover {
  border-bottom-color: #4c5458;
  color: #2a2f31;
}

.tab-link.w--current {
  border-bottom-color: #529dff;
  background-color: transparent;
  opacity: 1;
  color: #356af0;
  font-weight: 700;
}

.form-checkbox {
  margin-bottom: 16px;
  padding-left: 24px;
}

.text-box {
  max-width: 750px;
}

.text-box._400px {
  max-width: 400px;
  min-height: 64px;
}

.text-box._400px.visible {
  border-radius: 6px;
  background-color: #d0e8ff;
}

.text-box._500px {
  max-width: 500px;
  min-height: 64px;
}

.text-box._500px.visible {
  border-radius: 6px;
  background-color: #d0e8ff;
}

.text-box._600px {
  max-width: 600px;
  min-height: 64px;
}

.text-box._600px.visible {
  border-radius: 6px;
  background-color: #d0e8ff;
}

.text-box._800px {
  max-width: 800px;
  min-height: 64px;
}

.text-box._800px.visible {
  border-radius: 6px;
  background-color: #d0e8ff;
}

.text-box._700px {
  max-width: 700px;
  min-height: 64px;
}

.text-box._700px.visible {
  border-radius: 6px;
  background-color: #d0e8ff;
}

.ds-icon-grid {
  margin-top: 24px;
}

.check-box {
  width: 20px;
  height: 20px;
  margin-top: 0px;
  margin-right: 10px;
  margin-left: -24px;
}

.check-box.w--redirected-checked {
  border-color: #2b95ff;
  background-color: #2b95ff;
  background-position: 50% 50%;
  background-size: 16px;
}

.sg-title {
  margin-bottom: 8px;
  color: #3f3b35;
  font-size: 24px;
  line-height: 1.4em;
}

.icon {
  width: 55px;
  height: 55px;
  margin-bottom: 20px;
  color: #fff;
  font-size: 24px;
}

.block-quote {
  border-left-color: #9ec5fa;
  font-family: Inter, sans-serif;
  font-size: 18px;
  line-height: 1.4em;
}

.column-styleguide {
  width: 100%;
  height: 40px;
  border-radius: 6px;
  background-color: #d0e8ff;
}

.sg-section {
  position: relative;
  z-index: 0;
  padding: 60px 80px 60px 60px;
  text-align: left;
}

.sg-section.header {
  background-color: #356af0;
  color: #fff;
}

.form {
  margin-bottom: 8px;
}

.horizontal-line {
  width: 100%;
  height: 1px;
  margin-bottom: 36px;
  background-color: #1d1916;
  opacity: 0.2;
}

.horizontal-line.design-system {
  position: relative;
  bottom: -80px;
}

.ds-colour-grid {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 24px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.visible-spacer {
  display: inline-block;
  border-radius: 5px;
  background-color: #d0e8ff;
}

._2-1-grid {
  position: relative;
  display: -ms-grid;
  display: grid;
  max-width: 1000px;
  margin-right: auto;
  margin-bottom: 24px;
  margin-left: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  grid-auto-columns: 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  -ms-grid-columns: 2fr 1fr;
  grid-template-columns: 2fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.column {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 32px;
  padding-right: 16px;
  padding-left: 16px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.column.desk-9 {
  width: 75%;
}

.column.desk-2 {
  width: 16.66%;
}

.column.desk-3 {
  width: 25%;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.column.desk-4 {
  width: 33.33%;
}

.column.desk-12 {
  width: 100%;
}

.column.desk-7 {
  width: 58.333333333333336%;
}

.column.desk-11 {
  width: 91.66%;
}

.column.desk-10 {
  width: 83.33%;
}

.column.desk-5 {
  width: 41.66%;
}

.column.desk-6 {
  width: 50%;
}

.column.desk-8 {
  width: 66.66666666666667%;
}

.column.desk-1 {
  width: 8.33%;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.field-block {
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 20px;
  min-width: 50px;
  margin-bottom: 8px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border: 1px solid transparent;
}

.sg-description {
  max-width: 400px;
  color: #929aa1;
}

.radio-button {
  /* width: 20px; */
  height: 20px;
  margin-top: 0px;
  margin-right: 10px;
  margin-left: -24px;
}

.radio-button.w--redirected-checked {
  border-width: 6px;
  border-color: #2b95ff;
}

.white-link {
  color: #9ec5fa;
}

.ds-section-header {
  margin-bottom: 50px;
}

.rich-text {
  font-family: Inter, sans-serif;
  text-align: left;
}

.sg-nav {
  position: fixed;
  left: 0px;
  top: 0%;
  right: auto;
  bottom: 0px;
  width: 240px;
  height: 0px;
  border-right: 1px solid #ced5db;
}

.sg-nav-container {
  width: 240px;
  height: 100vh;
  background-color: #f0f2f3;
}

.sg-menu-brand {
  margin-top: 40px;
  margin-bottom: 50px;
  margin-left: 30px;
  padding-left: 0px;
}

.sg-menu {
  width: 100%;
}

.sg-menu-link {
  width: 100%;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 27px;
  border-left: 4px solid transparent;
  color: #333b4a;
}

.sg-menu-link:hover {
  color: #356af0;
}

.sg-menu-link.w--current {
  padding-right: 0px;
  background-color: #e1e5e7;
  color: #111314;
  font-weight: 600;
}

._wf-hero-section-1 {
  min-height: 700px;
  padding-top: 75px;
  padding-bottom: 75px;
  background-color: #2699fb;
}

._wf-hero-section-1.white-hero-section {
  min-height: auto;
  padding-top: 0px;
  padding-bottom: 0px;
  /* background-color: #f0f1ff; */
  background-color: transparent;
}

._wf-hero-section-1.hero-background-image {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 150px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: transparent;
  background-image: url('../images/Veneuly-Hero-Option-Img.jpg');
  background-position: 50% 75%;
  background-size: cover;
}

._wf-hero-container-2-blue {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #2699fb;
  text-align: left;
}

.header-2 {
  margin-top: 0px;
  font-family: Inter, sans-serif;
  color: #6568e6;
  font-size: 28px;
  line-height: 125%;
  font-weight: 600;
}

.header-2.hero {
  font-size: 36px;
  font-weight: 700;
}

.header-2.hero._02 {
  white-space: normal;
}

.header-2.hero._01 {
  display: none;
}

.subheader {
  margin-top: 15px;
  margin-bottom: 30px;
  color: #575757;
  font-size: 18px;
  line-height: 160%;
  font-weight: 400;
  text-align: center;
}

.subheader.white {
  color: #252525;
}

.subheader.white.hero {
  text-align: left;
}

.subheader.downtime {
  font-family: Inter, sans-serif;
  text-align: left;
}

._wf-normal-button {
  margin-top: 15px;
  margin-right: 15px;
  margin-bottom: 15px;
  padding: 17px 48px;
  border: 2px solid transparent;
  border-radius: 4px;
  background-color: #6568e6;
  font-size: 14px;
  font-weight: 300;
  text-transform: uppercase;
}
._wf-normal-button2 {
  margin-top: 15px;
  margin-right: 15px;
  margin-bottom: 15px;
  padding: 17px 48px;
  border: 2px solid transparent;
  border-radius: 4px;
  border-color: #6568e6;
  background-color: #fff;
  color: #6568e6;
  font-size: 14px;
  font-weight: 300;
  text-transform: uppercase;
}

._wf-normal-button:hover {
  border-color: #6568e6;
  background-color: #fff;
  color: #6568e6;
}
._wf-normal-button2:hover {
  background-color: #6568e6;
  color: #fff;
}

._wf-normal-button:active {
  background-color: #4144aa;
  color: #fff;
}

._wf-normal-button.white-button {
  background-color: #6568e6;
  color: #fff;
  text-decoration: none;
}

._wf-hero-image-2 {
  min-width: 50%;
  margin-left: 60px;
}

._wf-checklist {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: -10px;
  padding: 15px 0px 0px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

._wf-checklist._02 {
  margin-left: 25px;
}

._wf-checklist._04 {
  margin-top: 25px;
  margin-left: 25px;
}

._wf-checklist._03 {
  margin-top: 25px;
}

._wf-checklist.benefit {
  margin-bottom: 0px;
}

._wf-content-section {
  padding-top: 0px;
  padding-bottom: 0px;
  background-color: transparent;
}

._wf-content-section._02 {
  background-color: transparent;
}

._wf-content-image {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  max-height: 500px;
  min-height: 300px;
  margin-top: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background-image: url('../images/01.-we-support.jpg');
  background-position: 50% 50%;
  background-size: cover;
}

._wf-checklist-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.features-text {
  margin-top: 16px;
  font-family: Inter, sans-serif;
  color: #575757;
  font-size: 18px;
  line-height: 160%;
  font-weight: 400;
  text-align: left;
}

.checklist-main-header {
  margin-top: 0px;
  margin-bottom: 20px;
  font-family: Inter, sans-serif;
  color: #6568e6;
  font-size: 28px;
  line-height: 125%;
  font-weight: 600;
}

.horizontal-checklist-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 19px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.horizontal-checklist-with-image-text {
  width: 62%;
  padding-right: 40px;
  padding-left: 0px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.features-subheader {
  margin-bottom: 0px;
  font-size: 16px;
  line-height: 160%;
  font-weight: 400;
}

._wf-checklist-text {
  padding-left: 20px;
}

._wf-checklist-text.horizontal-text {
  padding-right: 20px;
  padding-left: 0px;
}

._wf-checklist-titile {
  margin-top: 0px;
  margin-bottom: -5px;
  font-family: Inter, sans-serif;
  color: #008a21;
  font-size: 21px;
  line-height: 150%;
  font-weight: 600;
  text-align: left;
}

._wf-content-video-container-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

._wf-features-item-left-aligned {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-width: 33%;
  margin-top: 0px;
  padding: 25px 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: flex-start;
  text-align: left;
}

._wf-features-item-left-aligned.image-content-text {
  min-width: 0%;
  padding-top: 0px;
  padding-bottom: 0px;
}

.feature-header {
  margin-top: 0px;
  font-family: Inter, sans-serif;
  color: #6568e6;
  font-size: 22px;
  line-height: 125%;
  font-weight: 600;
}

.feature-header._2nd {
  font-size: 28px;
}

.feature-header.main {
  color: #1b7d69;
  font-size: 20px;
}

.image-text {
  max-width: 52%;
  padding-right: 35px;
  padding-left: 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.image-text.image-text-on-the-right {
  padding-right: 0px;
  padding-left: 35px;
}

._wf-hero-container-2-white {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fff;
  text-align: left;
}

._wf-hero-container-2-white.background-image-text-container {
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.hero-section-text-with-background-image {
  width: 50%;
  padding: 30px;
  border-radius: 8px;
  background-color: hsla(0, 0%, 100%, 0.8);
  color: #2699fb;
}

.hero-section-v2 {
  /* margin-top: 80px; */
}

.checlist-image {
  height: 60px;
}

._wf-hero-container-blue {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #2699fb;
  text-align: center;
}

.checklist-main-text {
  padding-right: 0px;
  padding-left: 60px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.content-header-wrapper {
  padding-right: 48px;
  padding-left: 48px;
}

.checklist-wrapper {
  width: 42%;
}

.double-checklist-2 {
  width: 48%;
}

.double-checklist-1 {
  width: 48%;
  padding-right: 60px;
  padding-left: 0px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

._wf-outline-button {
  padding: 17px 48px;
  border: 2px solid #2699fb;
  border-radius: 4px;
  background-color: transparent;
  color: #2699fb;
  font-size: 14px;
  font-weight: 300;
  text-transform: uppercase;
}

._wf-outline-button:hover {
  background-color: #2699fb;
  color: #fff;
}

._wf-outline-button:active {
  border-color: #007feb;
  background-color: #007feb;
  color: #fff;
}

._wf-outline-button.white-outline {
  border-color: #fff;
  background-color: #f1f1f1;
  color: #001638;
}

._wf-outline-button.white-outline.submit {
  margin-top: 12px;
  padding-top: 14px;
  padding-bottom: 14px;
  border-color: #6568e6;
  background-color: #f0f1ff;
  color: #6568e6;
}

.text-field {
  height: 60px;
  min-width: 275px;
  margin-top: 12px;
  margin-right: 10px;
  margin-bottom: 12px;
  padding: 10px 25px;
  border-style: solid;
  border-width: 1px;
  border-color: #6568e6;
  border-radius: 5px;
  background-color: #f0f1ff;
  color: #6568e6;
}

.text-field::-webkit-input-placeholder {
  color: #2699fb;
}

.text-field:-ms-input-placeholder {
  color: #2699fb;
}

.text-field::-ms-input-placeholder {
  color: #2699fb;
}

.text-field::placeholder {
  color: #2699fb;
}

.text-field.name {
  margin-top: 0px;
  border-style: solid;
  border-width: 1px;
  border-color: #6568e6;
  background-color: #f0f1ff;
  color: #6568e6;
}

._wf-form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  color: #6568e6;
}

.date {
  color: #6568e6;
  font-size: 12px;
}

.footer-column-name {
  /* margin-bottom: 30px; */
  color: #6568e6;
  font-size: 12px;
}

.footer-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.footer-link {
  display: block;
  margin-top: 10px;
  margin-right: 5px;
  padding-bottom: 0px;
  color: #6568e6;
  font-size: 16px;
  line-height: 160%;
  text-decoration: none;
}

.footer-section {
  display: block;
  min-height: 300px;
  padding: 75px 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  background-color: #e3f3e4;
  color: #fff;
}

.footer-column {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 25%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.footer-column.email-culumn {
  padding: 25px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-style: solid;
  border-width: 1.5px;
  border-color: #6568e6;
  border-radius: 9px;
}

.grid-wrapper {
  display: block;
  padding-top: 0px;
  padding-bottom: 0px;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #f0f1ff;
}

.features-grid {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

._wf-content-header {
  margin-top: 20px;
  margin-bottom: -10px;
  color: #6568e6;
  font-size: 40px;
  line-height: 125%;
}

._wf-content-header.cta {
  font-family: Inter, sans-serif;
  font-size: 32px;
  text-decoration: underline;
}

._wf-content-header.cta.color {
  margin-top: 40px;
  margin-bottom: 40px;
  color: #6568e6;
  font-weight: 600;
  text-decoration: underline;
}

.feature-image {
  height: 450px;
  margin-bottom: 30px;
}

.feature-image.main {
  height: 350px;
  margin-top: 60px;
  margin-bottom: 60px;
}

.buttons-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.image {
  background-color: #008a21;
}

.final-cta-layout-v1 {
  margin-top: 0px;
  margin-bottom: 0px;
  margin: 120px 0;
}

.benefit-section {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 120px;
  padding-bottom: 120px;
  background-color: #f0f1ff;
}

.feature-section {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 120px;
  padding-bottom: 120px;
  background-color: transparent;
}

.feature-section._01 {
  background-color: #f0f1ff;
}

._wf-content-section-copy {
  display: none;
  padding-top: 75px;
  padding-bottom: 75px;
  background-color: #fff;
}

.feature-section-v2 {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 120px;
  padding-bottom: 120px;
}

.final-cta-sign-up-section {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 120px;
  padding-bottom: 120px;
  background-color: #f0f1ff;
}

.img-hero {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-top: 6%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.hero-section {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 80vh;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.hero-section.steps {
  display: none;
  min-height: auto;
  margin-top: 120px;
  margin-bottom: 120px;
}

.content-hero {
  width: 100%;
  text-align: center;
}

.steps {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.steps.dots {
  position: absolute;
  width: 80%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.hero-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.paragraph-2 {
  font-size: 18px;
}

.scroll-base {
  width: 100%;
  height: 6px;
  background-color: #eeeff4;
  -webkit-transform-origin: 0% 50%;
  -ms-transform-origin: 0% 50%;
  transform-origin: 0% 50%;
}

.dot-block {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 24px;
  height: 24px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
}

.time-wrap {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-top: 24px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.label {
  color: #006cd0;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.label.hero {
  margin-bottom: 40px;
  color: #6568e6;
}

.scroll-animate {
  position: absolute;
  bottom: 0px;
  z-index: 999;
  width: 100%;
  height: 6px;
  background-color: #006cd0;
  -webkit-transform-origin: 0% 50%;
  -ms-transform-origin: 0% 50%;
  transform-origin: 0% 50%;
}

.scroll-animate.s-2 {
  background-color: #6568e6;
}

.step-block-hero {
  position: relative;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  text-align: left;
}

.text-span {
  color: #006cd0;
}

.dot-hero {
  position: relative;
  z-index: 3;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #eeeff4;
}

.bg-shadow {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 1;
  border-radius: 16px;
  box-shadow: 7px 7px 20px 0 rgba(18, 18, 18, 0.07);
}

.scroll-hero {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 80%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.scoll-wrap-hero {
  position: absolute;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 78%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.step-hero {
  position: relative;
  z-index: 2;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 18px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 16px;
  background-color: #fff;
}

.step-scroll {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.trigger {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: auto;
  width: 100%;
  height: 100%;
}

.trigger._2 {
  top: 41%;
  height: 59%;
}

.trigger._1 {
  top: 15%;
  height: 85%;
}

.trigger._3 {
  top: 71%;
  height: 29%;
}

.scroll-line-wrap {
  position: absolute;
  left: -50%;
  top: auto;
  right: auto;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-bottom: 8px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.dot {
  position: relative;
  z-index: 3;
  width: 12px;
  height: 12px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  border-radius: 50%;
  background-color: #eeeff4;
}

.step-block {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100vw;
  height: 80vh;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.step-block._01 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.step-block._02 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.scroll-wrap {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 400vh;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.step {
  position: relative;
  z-index: 2;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 12%;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 16px;
  background-color: #fff;
}

.img-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 40%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}

.clone-section {
  margin-bottom: 50vh;
}

.heading-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
}

.scroll-container {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  overflow: hidden;
  width: 100%;
  height: 100vh;
}

.step-content-block {
  position: relative;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  text-align: left;
}

.content {
  margin-top: 24px;
  font-size: 24px;
  line-height: 1.4;
  font-weight: 500;
}

.content-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 40%;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.horizontal-scroll-section {
  display: none;
  margin-bottom: 50vh;
}

.block_wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 25%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}

.white_dot {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 100px;
  background-color: #fff;
  box-shadow: 1px 1px 60px 0 rgba(91, 58, 204, 0.55);
}

.line_02 {
  position: absolute;
  z-index: 999;
  width: 25%;
  height: 1px;
  margin-left: -12.5%;
  background-color: #f9f9f9;
}

.block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 25%;
  height: 120px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.line_01 {
  position: absolute;
  z-index: 999;
  width: 25%;
  height: 1px;
  margin-left: 12.5%;
  background-color: #f9f9f9;
}

.process-wrap {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 1140px;
  margin-right: auto;
  margin-left: auto;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.h1 {
  position: absolute;
  z-index: 15;
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: Inter, sans-serif;
  color: rgba(27, 125, 105, 0.2);
  font-size: 90px;
  line-height: 70px;
}

.plain_text {
  font-family: Inter, sans-serif;
  text-align: center;
}

.content_wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 16px;
  padding-left: 16px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.ring_03 {
  position: absolute;
  width: 120px;
  height: 120px;
  border-style: solid;
  border-width: 1px;
  border-color: #eee;
  border-radius: 100px;
  opacity: 0.3;
}

.h4 {
  margin-top: 0px;
  margin-bottom: 10px;
  font-family: Inter, sans-serif;
  color: #1b7d69;
  font-size: 16px;
}

.line_03 {
  position: absolute;
  z-index: 999;
  width: 25%;
  height: 1px;
  margin-left: -12.5%;
  background-color: #f9f9f9;
}

.green_dot {
  position: relative;
  z-index: 9;
  width: 10px;
  height: 10px;
  border-radius: 100px;
  background-color: #5b3acc;
}

.ring_01 {
  position: absolute;
  width: 40px;
  height: 40px;
  border: 1px solid #eee;
  border-radius: 100px;
}

.ring_02 {
  position: absolute;
  width: 80px;
  height: 80px;
  border-style: solid;
  border-width: 1px;
  border-color: #eee;
  border-radius: 100px;
  opacity: 0.6;
}

.dot_container {
  position: relative;
  z-index: 9999;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 15px;
  height: 15px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 100px;
}

._4-steps-section {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 80px;
  padding-bottom: 80px;
  background-color: #fff;
}

.consultation-contain {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50vw;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.consultation-section {
  margin-top: 40px;
  margin-bottom: 120px;
}

.f-q-section {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 120px;
  padding-bottom: 120px;
}

.faq-plus-wrap {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 20px;
  height: 20px;
  margin-right: 25px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.pricing-h1 {
  text-align: center;
}

.pricing-h1.faq-h2 {
  margin-bottom: 50px;
  font-family: Inter, sans-serif;
  color: #6568e6;
  font-weight: 600;
  text-align: center;
}

.faq-plus-l {
  position: absolute;
  width: 5px;
  height: 100%;
  border-radius: 3px;
  background-color: #6568e6;
}

.faq-q-text {
  font-family: Inter, sans-serif;
  color: #1b7d69;
  font-weight: 600;
}

.faq-plus {
  width: 100%;
  height: 5px;
  border-radius: 3px;
  background-color: #6568e6;
}

.faq-question {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 90px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 22px;
  font-weight: 800;
  cursor: pointer;
}

.container {
  position: relative;
  z-index: 2;
  display: block;
  width: 100%;
  max-width: 1400px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 5vw;
  padding-left: 5vw;
}

.container.faq-container {
  max-width: 900px;
  padding-top: 60px;
  padding-bottom: 80px;
  border-radius: 5px;
  background-color: hsla(0, 0%, 100%, 0.5);
  box-shadow: 0 0 50px 0 rgba(45, 62, 80, 0.06);
}

.link {
  color: #2aa8ff;
  font-weight: 600;
}

.faq-answer-text {
  margin-bottom: 30px;
  font-family: Inter, sans-serif;
  font-size: 16px;
  line-height: 24px;
}

.faq-answer {
  overflow: hidden;
  padding-left: 45px;
  border-bottom: 1px solid rgba(45, 62, 80, 0.12);
}

.faq-answer.last-faq-answer {
  border-bottom-width: 0px;
}

.consultation-icon {
  margin-bottom: 40px;
}

.process-icon {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 60px;
  height: 60px;
  min-height: 60px;
  min-width: 60px;
  margin-bottom: 24px;
  padding: 8px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 100px;
  background-color: #1b7d69;
  color: #fff;
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
}

.container-large {
  position: relative;
  z-index: 5;
  width: 100%;
  max-width: 1240px;
  margin-right: auto;
  margin-left: auto;
}

.heading-large {
  margin-bottom: 14px;
  font-family: Inter, sans-serif;
  color: #6568e6;
  font-size: 18px;
  line-height: 28px;
  font-weight: 700;
  letter-spacing: -0.02em;
}

.section-process {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  min-height: 720px;
  padding: 100px 5%;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.paragraph-standard {
  max-width: 600px;
  margin-bottom: 0px;
  color: #67718e;
  font-size: 16px;
  line-height: 28px;
  font-weight: 400;
  letter-spacing: -0.02em;
}

.title-large {
  margin-bottom: 16px;
  color: #0e0119;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.title-wrapper {
  max-width: 600px;
  margin-right: auto;
  margin-bottom: 45px;
  margin-left: auto;
  text-align: center;
}

.grid-process {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 40px;
  grid-row-gap: 72px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.card-process {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 32px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border: 1px solid #f2f3f8;
  border-radius: 20px;
}

.h3-heading {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: Inter, sans-serif;
  color: #6568e6;
  font-size: 28px;
  line-height: 60px;
  font-weight: 700;
  letter-spacing: -0.04em;
}

.text-span-2 {
  color: #6568e6;
  text-decoration: underline;
}
.text-span-landing-contact {
  margin-top: 20px;
  color: #6568e6;
}

._4-step-div-horizontal {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.process-icon-drawing {
  width: 150px;
}

.feature-image-02 {
  margin-top: 20px;
}

.div-block {
  display: none;
}

.arrow-circle {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 40px;
  height: 40px;
  min-height: 40px;
  min-width: 40px;
  margin-right: 18px;
  padding: 4px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #67718e;
  border-radius: 100px;
}

.grid-hero {
  grid-column-gap: 72px;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.header-paragraph {
  max-width: 600px;
  margin-bottom: 0px;
  color: #67718e;
  font-size: 18px;
  line-height: 32px;
  font-weight: 400;
}

.header-logo-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 100%;
  margin-top: 140px;
  padding: 40px 32px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
}

.hero-heading {
  margin-top: 0px;
  margin-bottom: 24px;
  color: #061237;
  font-size: 48px;
  line-height: 60px;
  font-weight: 900;
  letter-spacing: -0.02em;
}

.container-2 {
  position: relative;
  z-index: 5;
  width: 100%;
  max-width: 1140px;
  margin-right: auto;
  margin-left: auto;
}

.logo-image-3 {
  max-width: 100px;
}

.logo-image-3._01 {
  max-width: 120px;
}

.button-arrow {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: color 300ms ease;
  transition: color 300ms ease;
  color: #67718e;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: -0.04em;
  text-decoration: none;
}

.hero-image {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: 100% 50%;
  object-position: 100% 50%;
}

.header-logo {
  width: 100%;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.button-wrapper-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 60px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.header-section {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  padding-top: 140px;
  padding-right: 5%;
  padding-left: 5%;
}

.button-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 32px;
  padding: 14px 40px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 2px solid #1355ff;
  border-radius: 8px;
  background-color: #6568e6;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}

.button-header:hover {
  background-color: #fff;
  color: #1355ff;
}

.hero-image-block {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: 0%;
  width: 100%;
  height: 100%;
  max-width: 45%;
  background-color: #bebebe;
}

.title-wrapper-3 {
  max-width: 600px;
  margin-right: auto;
  margin-bottom: 92px;
  margin-left: auto;
  text-align: center;
}

.grid-feature-cards {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}
.fill__area{
  display: flex; 
  gap: 40px; 
  align-items: center;
}
.card-features {
  padding: 60px 32px 40px;
  border-style: solid;
  border-width: 10px 1px 1px;
  border-color: #6568e6 #dadde7 #dadde7;
  border-radius: 12px;
  background-color: #fff;
  height: 450px;
}

.h3-heading-2 {
  margin-top: 0px;
  margin-bottom: 24px;
  color: #061237;
  font-size: 48px;
  line-height: 60px;
  font-weight: 800;
  letter-spacing: -0.04em;
}

.paragraph-standard-2 {
  max-width: 600px;
  margin-bottom: 0px;
  color: #67718e;
  font-size: 16px;
  line-height: 32px;
  font-weight: 400;
  letter-spacing: -0.02em;
}

.paragraph-large-3 {
  max-width: 600px;
  margin-bottom: 0px;
  color: #67718e;
  font-size: 18px;
  line-height: 32px;
  font-weight: 400;
  letter-spacing: -0.02em;
}

.title-large-2 {
  margin-bottom: 16px;
  color: #6568e6;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.section-feature-cards {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  min-height: 720px;
  padding: 140px 5%;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.heading-large-2 {
  margin-bottom: 14px;
  font-family: Inter, sans-serif;
  color: #1b7d69;
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  letter-spacing: -0.02em;
}

.feature-icon-large-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 72px;
  height: 72px;
  min-height: 72px;
  min-width: 72px;
  margin-bottom: 16px;
  padding: 8px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 100px;
  background-color: #fff;
  box-shadow: 33px 33px 88px -12px rgba(6, 18, 55, 0.16);
}

.content-card {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 24px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-radius: 20px;
  color: #1a1a1a;
}

.content-card.content-green {
  background-color: #f4fbf6;
}

.content-card.content-yellow {
  background-color: #fffcf0;
}

.content-card.content-blue {
  background-color: #f4f5fb;
}

.margin-bottom-32 {
  margin-bottom: 32px;
}

.content-grid {
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.content-icon-gray {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 52px;
  height: 52px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #e6e6e6;
  border-radius: 14px;
  background-color: #f7f7f7;
}

.content-image {
  overflow: hidden;
  height: 100%;
  border: 1px solid #e6e6e6;
  border-radius: 20px;
}

.content-heading-wrapper {
  margin-bottom: 72px;
}

.h3-heading-3 {
  font-size: 48px;
  line-height: 1.2;
  letter-spacing: -0.02em;
}

.h3-heading-3.text-weight-medium {
  font-weight: 700;
}

.content-icon-square {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 52px;
  height: 52px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #b3bbe5;
  border-radius: 14px;
  background-color: #f4f5fb;
  color: #4255bd;
}

.grid-two-column-2 {
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.paragraph-small {
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.01em;
}

.content-icon-green {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 52px;
  height: 52px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #b3e5c3;
  border-radius: 14px;
}

.margin-bottom-08 {
  margin-bottom: 8px;
}

.margin-bottom-08.text-weight-medium {
  font-weight: 500;
}

.sub-heading-regular {
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.03em;
}

.heading-detail-section {
  margin-bottom: 8px;
  color: #b2b2b2;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 600;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  white-space: normal;
}

.content-icon-orange {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 52px;
  height: 52px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #fcd49c;
  border-radius: 14px;
  background-color: #fff9f0;
}

.content-icon-large {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 32px;
  height: 32px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.content-icon-yellow {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 52px;
  height: 52px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #fcec9c;
  border-radius: 14px;
  background-color: #fffcf0;
}

.section-large-3 {
  position: relative;
  display: none;
  padding: 120px 5%;
}

.image-cover {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.content-icon-red {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 52px;
  height: 52px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #f5a3a3;
  border-radius: 14px;
  background-color: #fef1f1;
}

.icon-square-01 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 80px;
  height: 80px;
  min-height: 80px;
  min-width: 80px;
  margin-bottom: 32px;
  padding: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #e9e7ff;
}

.feature-card-02 {
  display: block;
  padding: 32px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px dashed #e5e5e5;
}

.icon-square-02 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 80px;
  height: 80px;
  min-height: 80px;
  min-width: 80px;
  margin-bottom: 32px;
  padding: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #e3f3e4;
}

.feature-title {
  max-width: 300px;
  margin-top: 0px;
  margin-bottom: 16px;
  font-family: Inter, sans-serif;
  color: #4d4faf;
  font-size: 24px;
  line-height: 34px;
  font-weight: 500;
  letter-spacing: -0.02em;
}

.feature-details {
  color: #737373;
  font-size: 16px;
  line-height: 32px;
  font-weight: 400;
}

.heading-large-3 {
  margin-bottom: 24px;
  color: #262626;
  font-size: 50px;
  line-height: 60px;
  font-weight: 600;
  letter-spacing: -0.04em;
}

.icon-square-04 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 80px;
  height: 80px;
  min-height: 80px;
  min-width: 80px;
  margin-bottom: 32px;
  padding: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #e9e7ff;
}

.grid-features {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.grid-feature-wrapper {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 72px;
  grid-row-gap: 32px;
  -ms-grid-columns: 1fr 2fr;
  grid-template-columns: 1fr 2fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.section-feature {
  padding: 140px 5%;
}

.feature-card-01 {
  display: block;
  padding: 32px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-right: 1px dashed #e5e5e5;
  border-bottom: 1px dashed #e5e5e5;
}

.feature-card-03 {
  display: block;
  padding: 32px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-right: 1px dashed #e5e5e5;
}

.button-feature {
  margin-top: 32px;
  padding: 16px 32px;
  border-radius: 4px;
  background-color: #1a1e21;
}

.container-features {
  max-width: 1140px;
  margin-right: auto;
  margin-left: auto;
}

.icon-square-03 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 80px;
  height: 80px;
  min-height: 80px;
  min-width: 80px;
  margin-bottom: 32px;
  padding: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #e3f3e4;
}

.title-large-3 {
  margin-bottom: 16px;
  color: #8e8e8e;
  font-size: 16px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.feature-card-04 {
  display: block;
  padding: 32px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.button-neutral-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: auto;
  min-width: auto;
  padding: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 0px;
  background-color: transparent;
  outline-color: transparent;
  outline-offset: 0px;
  outline-style: none;
  outline-width: 0px;
  color: #fff;
  font-size: 14px;
  text-align: center;
  letter-spacing: -0.02em;
  text-decoration: none;
}

.button-neutral-2:hover {
  background-color: #282556;
}

.grid-two-column {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.button-secondary-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 48px;
  min-width: 120px;
  padding: 12px 32px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #e4e6f1;
  border-radius: 50px;
  background-color: transparent;
  -webkit-transition: background-color 300ms ease;
  transition: background-color 300ms ease;
  color: #160042;
  font-size: 14px;
  text-align: center;
  letter-spacing: -0.02em;
  text-decoration: none;
}

.button-secondary-2:hover {
  border-color: transparent;
  background-color: #f3f5fb;
}

.margin-bottom-12 {
  margin-bottom: 12px;
}

.button-wrapper-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 32px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
}

.heading-detail-small {
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
}

.section-small {
  padding: 120px 5%;
}

.container-regular-2 {
  position: relative;
  width: 100%;
  max-width: 1140px;
  margin-right: auto;
  margin-left: auto;
}

.placeholder-image-tall {
  overflow: hidden;
  width: 100%;
  height: 640px;
  max-width: 500px;
  border-radius: 12px;
}

.paragraph-large {
  font-size: 18px;
  line-height: 1.8;
  letter-spacing: -0.02em;
}

.header-section-2 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 100px 5%;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.hero-heading-2 {
  margin-top: 0px;
  margin-bottom: 24px;
  color: #0c0c0c;
  font-size: 56px;
  line-height: 68px;
  letter-spacing: -0.02em;
}

.image-wrapper {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.arrow-circle-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 40px;
  height: 40px;
  min-height: 40px;
  min-width: 40px;
  margin-right: 18px;
  padding: 4px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #202020;
  border-radius: 100px;
}

.header-paragraph-2 {
  max-width: 460px;
  margin-bottom: 0px;
  color: #565656;
  font-size: 16px;
  line-height: 32px;
  font-weight: 500;
}

.accent-square-small-right {
  position: relative;
  right: -32px;
  width: 32px;
  height: 32px;
  background-color: #008a21;
}

.container-5 {
  position: relative;
  z-index: 5;
  width: 100%;
  max-width: 1160px;
  margin-right: auto;
  margin-left: auto;
}

.accent-square-wrapper {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.accent-wrapper-left {
  position: absolute;
  left: -4%;
  top: auto;
  right: auto;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 90%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transform: skew(10deg, 0deg);
  -ms-transform: skew(10deg, 0deg);
  transform: skew(10deg, 0deg);
}

.accent-line {
  width: 2px;
  height: 100%;
  background-color: #000;
}

.accent-wrapper-right {
  position: absolute;
  left: auto;
  top: auto;
  right: 0%;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 80%;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-transform: skew(10deg, 0deg);
  -ms-transform: skew(10deg, 0deg);
  transform: skew(10deg, 0deg);
}

.header-grid {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 1px;
  grid-row-gap: 80px;
  -ms-grid-columns: 1fr 1.1fr;
  grid-template-columns: 1fr 1.1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.accent-square-small-left {
  position: relative;
  width: 32px;
  height: 32px;
  background-color: #6568e6;
}

.accent-square-large {
  position: relative;
  left: -32px;
  width: 65px;
  height: 75px;
  background-color: #6568e6;
}

.hero-image-2 {
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.button-hero {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 40px;
  padding: 14px 40px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 2px solid #202020;
  border-radius: 8px;
  background-color: #202020;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}

.button-hero:hover {
  background-color: #fff;
  color: #271d0c;
}

.button-arrow-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: color 300ms ease;
  transition: color 300ms ease;
  color: #202020;
  font-size: 18px;
  letter-spacing: -0.04em;
  text-decoration: none;
}

.hero-block-2 {
  max-width: 600px;
}

.accent-square-small {
  width: 32px;
  height: 32px;
  background-color: #008a21;
}

.button-wrapper-4 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 60px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.header-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 532px;
  padding-top: 60px;
  padding-bottom: 80px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.header-image {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  height: 100%;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.container-8 {
  width: 100%;
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
}

.accent-wrapper-2 {
  position: relative;
  margin-right: 10px;
}

.header-grid-4 {
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 100px;
  grid-row-gap: 42px;
  -ms-grid-columns: 1fr 1.3fr;
  grid-template-columns: 1fr 1.3fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.header-paragraph-6 {
  max-width: 350px;
  color: #000;
  font-size: 18px;
  line-height: 1.5;
}

.header-section-7 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 5%;
  padding-left: 5%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
}

.header-scooter {
  position: relative;
  z-index: 2;
  width: 90%;
  max-width: 750px;
}

.add-to-cart {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  color: #000;
  font-weight: 500;
  text-decoration: none;
}

.add-to-cart:hover {
  color: #99b5ac;
}

.button-wrapper-5 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.heading-title {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: Inter, sans-serif;
  color: #575acc;
  font-size: 70px;
  line-height: 82px;
  font-weight: 500;
  letter-spacing: -0.03em;
}

.heading-title._02 {
  font-size: 45px;
  line-height: 58px;
  font-weight: 500;
}

.heading-title._01 {
  color: #575acc;
}

.arrow-large {
  margin-top: 80px;
}

.button-3 {
  margin-right: 30px;
  padding: 22px 52px;
  border: 1px solid #99b5ac;
  background-color: rgba(0, 138, 33, 0.42);
  -webkit-transition: color 200ms ease, background-color 200ms ease,
    border-color 200ms ease;
  transition: color 200ms ease, background-color 200ms ease,
    border-color 200ms ease;
}

.button-3:hover {
  border: 1px solid #99b5ac;
  background-color: #fff;
  color: #99b5ac;
}

.add-to-cart-text {
  margin-right: 16px;
}

.line-accent {
  position: absolute;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: -10px;
  color: #008a21;
  font-size: 14px;
  font-weight: 700;
}

.star {
  margin-left: 20px;
}

.header-block {
  position: absolute;
  width: 100%;
  height: 100%;
  max-width: 400px;
  background-color: rgba(101, 104, 230, 0.23);
}

.number-large {
  margin-top: 26px;
  margin-bottom: 40px;
  color: #000;
  font-size: 40px;
  line-height: 1.1;
  font-weight: 400;
}

.header-title-wrapper-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 34px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.grid-content {
  margin-bottom: 200px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 92px;
  grid-row-gap: 40px;
  -ms-grid-columns: 1.2fr 1fr;
  grid-template-columns: 1.2fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.card-line {
  width: 100%;
  height: 1px;
  margin-top: 24px;
  margin-bottom: 60px;
  border: 1px dashed #6667ab;
}

.container-large-3 {
  width: 100%;
  max-width: 1280px;
  margin-right: auto;
  margin-left: auto;
}

.brower-top-bar {
  width: 100%;
}

.grid-content-bottom {
  margin-bottom: 0px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 100px;
  grid-row-gap: 40px;
  -ms-grid-columns: 1fr 1.5fr;
  grid-template-columns: 1fr 1.5fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.placeholder-image {
  position: relative;
  z-index: 5;
  box-shadow: 7px 7px 29px 10px rgba(43, 44, 79, 0.15);
}

.heading-detail-small-2 {
  margin-bottom: 10px;
  color: #6667ab;
  font-weight: 500;
}

.heading-wrapper-2 {
  max-width: 500px;
  margin-right: auto;
  margin-bottom: 120px;
  margin-left: auto;
  text-align: center;
}

.browser-inner {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 24px;
  padding-right: 24px;
  padding-left: 24px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: #6667ab;
}

.grid-feature-2 {
  grid-row-gap: 32px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto auto auto;
  grid-template-rows: auto auto auto;
}

.feature-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.bg-vector {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  z-index: -1;
  margin-right: auto;
  margin-left: auto;
}

.h1-heading-3 {
  margin-bottom: 16px;
  font-family: Inter, sans-serif;
  color: #575acc;
  font-size: 48px;
  line-height: 58px;
  font-weight: 600;
  letter-spacing: -0.04em;
}

.section-large-2 {
  position: relative;
  overflow: hidden;
  padding: 120px 5% 0px;
  background-color: #f0f1ff;
}

.section-large-2._02 {
  padding-bottom: 120px;
  background-color: transparent;
}

.paragraph-3 {
  margin-bottom: 0px;
  color: #2b2c4f;
  font-size: 14px;
  line-height: 24px;
}

.vector-wave {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.icon-regular-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 24px;
  height: 24px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.browser {
  width: 100%;
}

.footnote-right {
  margin-top: 20px;
  margin-bottom: 0px;
  opacity: 0.5;
  color: #2b2c4f;
  line-height: 24px;
  text-align: right;
}

.h3-heading-4 {
  color: #2b2c4f;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: -0.04em;
}

.sub-heading-large-2 {
  margin-bottom: 10px;
  font-family: Inter, sans-serif;
  color: #1b7d69;
  font-size: 22px;
  line-height: 32px;
  font-weight: 500;
  letter-spacing: -0.02em;
}

.footnote {
  margin-top: 20px;
  margin-bottom: 0px;
  opacity: 0.5;
  color: #2b2c4f;
  line-height: 24px;
}

.placeholder-hand {
  position: relative;
  z-index: 5;
}

.icon-circle-3 {
  display: block;
  width: 36px;
  height: 36px;
  min-height: 3px;
  min-width: 36px;
  margin-right: 20px;
  padding: 4px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px dashed #2b2c4f;
  border-radius: 100px;
  color: #6667ab;
}

.browser-content-wrapper {
  padding-right: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  border-style: none dashed dashed;
  border-width: 2px;
  border-color: #6667ab;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.section-why {
  padding: 140px 5%;
}

.section-how {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  min-height: 720px;
  padding: 120px 5%;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #f0f1ff;
}

.become-a-host-section {
  padding: 120px 5%;
}

.become-a-host-section.main {
  background-color: #f0f1ff;
}

.feature-icon-small {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 48px;
  height: 48px;
  min-height: 48px;
  min-width: 48px;
  margin-right: 16px;
  margin-bottom: 32px;
  padding: 4px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 100px;
  background-color: #fff;
  box-shadow: 33px 33px 88px -12px rgba(6, 18, 55, 0.16);
}

.feature-icon-small._02 {
  background-color: #f0f1ff;
}

.number {
  font-family: Inter, sans-serif;
  color: #1b7d69;
  font-size: 22px;
  line-height: 32px;
  font-weight: 500;
  letter-spacing: -0.02em;
}

.span-blue-3 {
  color: #4255bd;
}

.cta-title-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #1a1a1a;
  text-align: center;
}

.grid-button {
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.grid-button.margin-top-64 {
  margin-top: 32px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
}

.grid-button.margin-top-64.horizontal {
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
}

.span-green {
  color: #40bf6a;
}

.cta-card-03 {
  overflow: hidden;
  height: 180px;
  margin-top: 32px;
  border-radius: 14px;
  background-color: #fcd49c;
}

.span-orange {
  color: #f99406;
}

.cta-card-02 {
  overflow: hidden;
  height: 180px;
  margin-top: 32px;
  border-radius: 14px;
  background-color: #b3e5c3;
}

.button-outline-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 48px;
  padding: 8px 16px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1.5px;
  border-color: #1b7d69;
  border-radius: 8px;
  background-color: transparent;
  -webkit-transition: border-color 400ms ease;
  transition: border-color 400ms ease;
  color: #4255bd;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
}

.button-outline-2:hover {
  border-color: #d3d3d3;
}

.heading-wrapper-4 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 720px;
  margin-right: auto;
  margin-bottom: 64px;
  margin-left: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.heading-wrapper-4.margin-bottom-64 {
  margin-bottom: 64px;
}

.button-primary {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 48px;
  padding: 12px 24px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 12px;
  background-color: #6568e6;
  color: #fff;
  font-weight: 600;
  text-decoration: none;
}

.integration-detail-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #1a1a1a;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
}

.section-regular {
  position: relative;
  padding: 92px 5%;
}

.cta-card-01 {
  overflow: hidden;
  height: 180px;
  margin-top: 32px;
  border-radius: 14px;
  background-color: #b3bbe5;
}

.container-large-2 {
  position: relative;
  width: 100%;
  max-width: 1280px;
  margin-right: auto;
  margin-left: auto;
}

.grid-three-column {
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.cta-card {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 24px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border: 1px solid #e6e6e6;
  border-radius: 20px;
  -webkit-transition: box-shadow 300ms ease;
  transition: box-shadow 300ms ease;
}

.cta-card:hover {
  box-shadow: 15px 32px 64px -14px rgba(77, 77, 77, 0.16);
}

.heading-content {
  margin-top: 0px;
  margin-bottom: 24px;
  font-family: Inter, sans-serif;
  color: #6568e6;
  font-size: 48px;
  line-height: 1.2;
  font-weight: 500;
  letter-spacing: -0.04em;
}

.heading-content.cta {
  color: #575acc;
  font-size: 62px;
}

.icon-small-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 16px;
  height: 16px;
  margin-right: 4px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.h6-heading-2 {
  margin-top: 0px;
  color: #575acc;
  font-size: 28px;
  line-height: 34px;
}

.heading-detail-section-2 {
  margin-bottom: 8px;
  color: #b2b2b2;
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  white-space: normal;
}

.text-block-2 {
  color: #1b7d69;
}

.cta-image-block {
  position: static;
  width: 100%;
  height: 350px;
  max-width: 100%;
  margin-top: 58px;
  background-image: url('../images/fill-your-congregation-down-time.jpeg');
  background-position: 50% 50%;
  background-size: cover;
  background-attachment: scroll;
  background-clip: border-box;
  -webkit-text-fill-color: inherit;
}

.text-span-3 {
  background-image: url('../images/Venuely-Verified-Icon-02.png');
  background-position: 0px 0px;
  background-size: auto;
}

.faq-rich-text {
  font-family: Inter, sans-serif;
  font-size: 16px;
  line-height: 1.6;
}
._wf-hero-section-1.white-hero-section{
  padding: 140px 0;
}

@media screen and (min-width: 1280px) {
  .sticky-nav {
    background-color: #fff;
  }

  .sg-content {
    margin-left: 280px;
  }

  .sg-nav {
    width: 280px;
  }

  .sg-nav-container {
    width: 280px;
  }
  ._wf-hero-section-1.white-hero-section {
    /* background-color: #f0f1ff; */
    background-color: transparent;
  }

  ._wf-hero-section-1.hero-background-image {
    background-image: url('../images/Veneuly-Hero-Option-Img.jpg');
    background-position: 50% 60%;
    background-size: cover;
  }

  ._wf-hero-container-2-blue {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: row-reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }

  .header-2 {
    color: #6568e6;
  }

  .subheader {
    font-family: Inter, sans-serif;
    color: #6568e6;
  }

  .subheader.downtime {
    text-align: left;
  }

  ._wf-normal-button {
    background-color: #6568e6;
  }

  ._wf-normal-button.white-button {
    background-color: #6568e6;
    color: #fff;
  }

  ._wf-hero-image-2 {
    margin-right: 60px;
    margin-left: 0px;
  }

  ._wf-checklist-container {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: row-reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }

  .features-text {
    font-size: 16px;
  }

  .checklist-main-header {
    font-family: Inter, sans-serif;
    color: #6568e6;
  }

  .horizontal-checklist-with-image-text {
    padding-right: 0px;
    padding-left: 40px;
  }

  ._wf-checklist-titile {
    color: #1b7d69;
  }

  ._wf-content-video-container-2 {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: row-reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }

  ._wf-features-item-left-aligned.image-content-text {
    padding-right: 20px;
  }

  .feature-header {
    font-family: Inter, sans-serif;
    color: #6568e6;
    font-size: 25px;
  }

  .checklist-main-text {
    padding-right: 0px;
    padding-left: 60px;
  }

  .footer-section {
    background-color: #e3f3e4;
  }

  ._wf-content-header {
    font-family: Inter, sans-serif;
    color: #6568e6;
    font-size: 36px;
  }

  .final-cta-layout-v1 {
    /* background-color: #f6f6ff; */
    /* padding: 140px 0; */
  }

  .benefit-section {
    margin-top: 120px;
    margin-bottom: 120px;
  }

  .feature-section {
    margin-top: 120px;
    margin-bottom: 120px;
  }

  .feature-section-v2 {
    margin-top: 120px;
    margin-bottom: 120px;
  }
}

@media screen and (min-width: 1920px) {
  ._wf-hero-section-1.white-hero-section {
    background-color: transparent;
  }

  ._wf-hero-section-1.hero-background-image {
    background-position: 50% 30%;
  }

  ._wf-hero-container-2-blue {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  ._wf-hero-image-2 {
    margin-right: 0px;
    margin-left: 60px;
  }

  .footer-section {
    background-color: #e3f3e4;
  }

  .final-cta-layout-v1 {
    background-color: transparent;
  }

  .process-icon-drawing {
    width: 100px;
    height: 100px;
  }

  .container-2 {
    max-width: 1250px;
  }

  .section-feature-cards {
    padding-top: 200px;
    padding-bottom: 200px;
  }

  .header-grid {
    -ms-grid-columns: 0.25fr 1.1fr;
    grid-template-columns: 0.25fr 1.1fr;
  }

  .section-how {
    padding-top: 200px;
    padding-bottom: 200px;
  }
}

@media screen and (max-width: 991px) {
  .button {
    position: relative;
  }

  .button.light {
    font-size: 18px;
    line-height: 1.4;
  }

  .grid {
    margin-left: 0px;
  }

  .sg-content {
    position: static;
    width: 100%;
    margin-left: 0px;
    border-left-style: none;
  }

  ._12-columns {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  ._1-2-grid {
    margin-left: 0px;
  }

  .small-button {
    position: relative;
  }

  .sg-block {
    margin-bottom: 40px;
  }

  ._2-1-grid {
    margin-left: 0px;
  }

  .column {
    width: 50%;
  }

  .column.desk-3 {
    width: 33.33%;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .column.desk-5 {
    width: 50%;
  }

  .sg-nav {
    position: -webkit-sticky;
    position: sticky;
    left: 0px;
    right: 0px;
    bottom: auto;
    z-index: 10;
    overflow: visible;
    width: 100%;
    height: 80px;
    padding-top: 20px;
    border-right-style: none;
    background-color: #f0f2f3;
  }

  .sg-nav-container {
    width: auto;
    height: auto;
  }

  .sg-menu-brand {
    margin-top: 4px;
    margin-bottom: 16px;
  }

  .sg-menu {
    position: absolute;
    left: 0%;
    top: 80px;
    right: auto;
    bottom: auto;
    z-index: 500;
    display: block;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #e1e5e7;
    background-color: #f0f2f3;
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.05);
  }

  .sg-menu-button {
    top: -10px;
    margin-right: 12px;
    padding: 15px;
  }

  .sg-menu-button.w--open {
    border-radius: 40px;
    background-color: #d0e8ff;
    color: #356af0;
  }

  .sg-menu-icon {
    font-size: 32px;
  }

  ._wf-hero-section-1 {
    padding-right: 20px;
    padding-left: 20px;
  }

  .subheader {
    font-size: 15px;
  }

  ._wf-checklist._02 {
    margin-left: 0px;
    padding-left: 25px;
  }

  ._wf-content-section {
    padding-right: 20px;
    padding-left: 20px;
  }

  .features-text {
    max-width: 500px;
    font-size: 14px;
  }

  .checklist-main-header {
    font-size: 22px;
  }

  .features-subheader {
    font-size: 15px;
  }

  ._wf-checklist-titile {
    font-size: 22px;
  }

  .feature-header {
    font-size: 22px;
  }

  .image-text {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .hero-section-text-with-background-image {
    width: 70%;
  }

  .double-checklist-1 {
    padding-right: 0px;
  }

  ._wf-outline-button.white-outline.submit {
    padding-right: 30px;
    padding-left: 30px;
  }

  .text-field {
    min-width: 245px;
  }

  .footer-link {
    font-size: 14px;
  }

  .footer-section {
    padding-right: 20px;
    padding-left: 20px;
  }

  .footer-column {
    width: 20%;
  }

  .features-grid {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  ._wf-content-header {
    font-size: 32px;
  }

  .feature-image {
    height: 120px;
  }

  .buttons-wrapper {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  ._wf-content-section-copy {
    padding-right: 20px;
    padding-left: 20px;
  }

  .img-hero {
    width: 100%;
    min-height: 40vh;
    margin-top: 5%;
    margin-bottom: 0%;
  }

  .hero-section {
    min-height: 90vh;
    padding-top: 10%;
    padding-right: 10%;
    padding-left: 10%;
  }

  .content-hero {
    width: 100%;
  }

  .hero-block {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .dot-block {
    width: 24px;
  }

  .scoll-wrap-hero {
    width: 77%;
  }

  .clone-section {
    margin-bottom: 20vh;
  }

  .horizontal-scroll-section {
    margin-bottom: 20vh;
  }

  .block_wrap {
    width: 50%;
  }

  .line_02 {
    width: 50%;
    margin-left: 25%;
  }

  .line_01 {
    width: 50%;
    margin-left: 25%;
  }

  .section-process {
    padding-bottom: 140px;
  }

  .grid-process {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .grid-hero {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .header-logo-block {
    margin-top: 40px;
    margin-bottom: 40px;
    padding-right: 0px;
    padding-left: 0px;
  }

  .header-section {
    padding-top: 100px;
    padding-bottom: 100px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .hero-image-block {
    position: relative;
    left: 0%;
    top: auto;
    right: 0%;
    bottom: 0%;
    overflow: hidden;
    height: 440px;
    max-width: 100%;
    border-radius: 20px;
  }

  .grid-feature-cards {
    max-width: 480px;
    margin-right: auto;
    margin-left: auto;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .section-feature-cards {
    padding-bottom: 140px;
  }

  .grid-two-column-2 {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .grid-features {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .grid-feature-wrapper {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .grid-two-column {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .placeholder-image-tall {
    height: 500px;
    max-width: 100%;
  }

  .header-grid {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .hero-block-2 {
    margin-right: auto;
    margin-left: auto;
  }

  .header-content {
    max-width: none;
    padding-bottom: 0px;
  }

  .header-image {
    min-height: 630px;
  }

  .header-grid-4 {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .header-section-7 {
    padding-right: 8%;
    padding-left: 8%;
    background-position: 50% 0%;
  }

  .grid-content {
    margin-bottom: 60px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .grid-content-bottom {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .section-large-2 {
    padding-top: 60px;
  }

  .section-how {
    padding-bottom: 140px;
  }

  .grid-three-column {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 767px) {
  .sticky-nav {
    position: relative;
  }
  .fill__area{
    flex-direction: column;
    padding: 0 20px;
  }

  .nav-grid {
    text-align: center;
  }

  .button.minimal {
    margin-right: 0px;
    margin-left: 0px;
  }

  .grid {
    margin-left: 0px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .grid.desk-3.tab-3 {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .grid.desk-2.tab-2 {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .grid.desk-4.tab-4 {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .paragraph {
    font-size: 15px;
  }

  .sg-content {
    margin-left: 0px;
  }

  .spacer {
    height: 32px;
  }

  .spacer._64 {
    height: 48px;
  }

  ._12-columns {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .ds-colour-block {
    width: 25%;
  }

  .tabs-menu {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  ._1-2-grid {
    margin-left: 0px;
    -ms-grid-columns: 2fr;
    grid-template-columns: 2fr;
  }

  .heading.h1 {
    font-size: 35px;
  }

  .heading.h2 {
    font-size: 28px;
  }

  .heading.h4 {
    font-size: 20px;
  }

  .tab-link {
    width: auto;
    border-bottom-width: 0px;
    border-radius: 10px;
  }

  .tab-link.w--current {
    background-color: #d4e9ff;
  }

  .sg-section {
    padding-right: 30px;
    padding-left: 30px;
  }

  ._2-1-grid {
    margin-left: 0px;
    -ms-grid-columns: 2fr;
    grid-template-columns: 2fr;
  }

  .column.desk-4 {
    width: 100%;
  }

  .column.desk-5 {
    width: 100%;
  }

  .column.desk-6 {
    width: 100%;
  }

  .field-block {
    min-height: auto;
  }

  .sg-menu-brand.w--current {
    margin-top: 14px;
  }

  .sg-menu {
    background-color: #fff;
  }

  ._wf-hero-section-1 {
    min-height: 600px;
  }

  ._wf-hero-container-2-blue {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  ._wf-hero-image-2 {
    height: 400px;
    margin-top: 50px;
    margin-bottom: 50px;
    margin-left: 0px;
  }

  ._wf-checklist {
    margin-bottom: 0px;
  }

  ._wf-checklist._02 {
    padding-left: 0px;
  }

  ._wf-checklist._04 {
    margin-left: 0px;
  }

  ._wf-content-image {
    width: 100%;
  }

  ._wf-checklist-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .horizontal-checklist-with-image-text {
    width: 100%;
    padding-right: 0px;
    padding-bottom: 50px;
  }

  ._wf-content-video-container-2 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .image-text {
    max-width: none;
    padding-right: 0px;
  }

  .image-text.image-text-on-the-right {
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 0px;
  }

  ._wf-hero-container-2-white {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .checklist-wrapper {
    width: 100%;
    padding-top: 42px;
    padding-bottom: 42px;
  }

  .double-checklist-2 {
    width: 100%;
  }

  .double-checklist-1 {
    width: 100%;
  }

  .footer-column-name {
    /* margin-bottom: 20px; */
  }

  .footer-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .footer-section {
    min-height: 0px;
  }

  .footer-column {
    width: 80%;
    margin-bottom: 60px;
  }

  ._wf-content-header {
    font-size: 34px;
  }

  .hero-section {
    padding-right: 4%;
    padding-left: 4%;
  }

  .scroll-base {
    position: relative;
  }

  .dot-block {
    min-height: 10vh;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2;
  }

  .scroll-hero {
    min-height: auto;
  }

  .scoll-wrap-hero {
    width: 76%;
  }

  .scroll-line-wrap {
    bottom: 50%;
    z-index: 1;
    margin-bottom: -3px;
  }

  .dot {
    position: static;
    z-index: 99;
  }

  .step-block {
    width: 100vw;
    min-height: 110vh;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .step {
    padding: 8%;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .img-block {
    position: relative;
    z-index: 3;
    max-height: 40vh;
    min-height: 40vh;
    padding-top: 20px;
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
    -ms-flex-order: 3;
    order: 3;
  }

  .img-block.anima-1 {
    max-width: 90%;
  }

  .step-content-block {
    z-index: 2;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1;
  }

  .content-wrap {
    width: 90%;
    height: 40vh;
    padding-bottom: 20px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .consultation-contain {
    width: 80vw;
  }

  .pricing-h1 {
    font-size: 34px;
    line-height: 44px;
  }

  .title-wrapper {
    margin-bottom: 60px;
  }

  .grid-process {
    grid-row-gap: 24px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .hero-content-block {
    text-align: center;
  }

  .header-logo {
    justify-items: center;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .button-wrapper-3 {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .header-section {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .title-wrapper-3 {
    margin-bottom: 60px;
  }

  .content-grid {
    grid-column-gap: 24px;
    grid-row-gap: 24px;
  }

  .h3-heading-3 {
    font-size: 40px;
  }

  .grid-two-column-2 {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .section-large-3 {
    padding-top: 72px;
    padding-bottom: 72px;
  }

  .grid-features {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .section-feature {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .feature-card-01 {
    border-right-style: none;
  }

  .feature-card-03 {
    border-bottom: 1px dashed #e5e5e5;
    border-right-style: none;
  }

  .feature-card-04 {
    border-bottom: 1px dashed #e5e5e5;
  }

  .grid-two-column {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .section-small {
    padding-top: 72px;
    padding-bottom: 72px;
  }

  .placeholder-image-tall {
    height: 400px;
  }

  .header-image {
    min-height: 500px;
  }

  .header-block {
    width: 50%;
  }

  .section-why {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .become-a-host-section {
    padding-top: 72px;
    padding-bottom: 72px;
  }

  .section-regular {
    padding-top: 72px;
    padding-bottom: 72px;
  }

  .grid-three-column {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .cta-card {
    max-width: 400px;
    margin-right: auto;
    margin-left: auto;
  }

  .heading-content {
    font-size: 48px;
  }
}

@media screen and (max-width: 479px) {
  .sticky-nav {
    padding: 0px;
  }
  .nav-grid {
    display: none;
    grid-auto-flow: row;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: 100px;
    grid-template-rows: 100px;
  }

  .nav-logo-link {
    display: none;
    border-top: 1px solid #c4c4c4;
  }

  .nav-link {
    display: none;
    padding-top: 10px;
    padding-bottom: 10px;
    border-top: 1px solid #c4c4c4;
  }

  .nav-link.log-in {
    padding-right: 0px;
  }

  .text-input {
    width: 100%;
  }

  .button {
    width: 100%;
    font-size: 16px;
  }

  .color-block {
    margin-bottom: 20px;
  }

  .sg-section {
    padding-right: 16px;
    padding-left: 16px;
  }

  .column {
    margin-bottom: 0px;
  }

  .column.desk-3 {
    width: 100%;
  }

  .column.desk-5 {
    width: 100%;
  }

  .column.desk-6 {
    width: 100%;
  }

  .sg-menu-brand {
    margin-left: 16px;
  }

  .sg-menu-button {
    margin-right: 4px;
  }

  ._wf-hero-section-1.white-hero-section {
    padding-top: 24px;
  }

  ._wf-hero-section-1.hero-background-image {
    min-height: 500px;
    padding-top: 75px;
  }

  ._wf-hero-container-2-blue {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .header-2 {
    font-size: 28px;
    text-align: left;
  }

  .header-2.consultation {
    text-align: center;
  }

  .subheader {
    text-align: left;
  }

  ._wf-normal-button {
    display: none;
    margin-top: 25px;
    margin-right: 0px;
    margin-bottom: 25px;
  }

  ._wf-normal-button.white-button {
    margin-right: 0px;
    text-align: center;
  }

  ._wf-hero-image-2 {
    height: 300px;
    margin-top: 20px;
    -o-object-fit: cover;
    object-fit: cover;
  }

  ._wf-content-section {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  ._wf-content-image {
    min-height: 200px;
  }

  .checklist-main-header {
    font-size: 28px;
  }

  .horizontal-checklist-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .features-subheader {
    font-size: 16px;
  }

  ._wf-checklist-titile {
    font-size: 19px;
  }

  ._wf-features-item-left-aligned {
    padding-right: 0px;
    padding-left: 0px;
  }

  ._wf-features-item-left-aligned.image-content-text {
    padding-right: 0px;
    padding-left: 0px;
  }

  .feature-header {
    font-size: 16px;
  }

  .image-text {
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1;
  }

  ._wf-hero-container-2-white {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .hero-section-text-with-background-image {
    position: relative;
    top: 280px;
    z-index: 99;
    width: 100%;
    background-color: hsla(0, 0%, 100%, 0.8);
    box-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.18);
  }

  .checklist-main-text {
    padding-right: 0px;
    padding-left: 0px;
  }

  .content-header-wrapper {
    padding-right: 0px;
    padding-left: 0px;
  }

  .double-checklist-1 {
    padding-right: 0px;
  }

  ._wf-outline-button.white-outline {
    text-align: center;
  }

  ._wf-outline-button.white-outline.submit {
    margin-top: 13px;
    padding-top: 7px;
    padding-bottom: 7px;
  }

  .text-field {
    margin-right: -1px;
  }

  ._wf-form {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .date {
    text-align: center;
  }

  .footer-column-name {
    text-align: center;
  }

  .footer-link {
    font-size: 16px;
    text-align: left;
  }

  .footer-section {
    display: block;
  }

  .footer-column {
    width: 100%;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .footer-column.email-culumn {
    width: 100%;
  }

  ._wf-content-header {
    font-size: 24px;
  }

  ._wf-content-header.cta {
    font-size: 32px;
    text-align: left;
  }

  .feature-image {
    height: 320px;
    -o-object-fit: cover;
    object-fit: cover;
  }

  .buttons-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  ._wf-content-section-copy {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .final-cta-sign-up-section.main {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .img-hero {
    min-height: 30vh;
  }

  .steps.dots {
    width: 100%;
    padding-top: 9%;
    padding-bottom: 9%;
  }

  .scroll-hero {
    width: 100%;
    margin-top: 5%;
  }

  .scoll-wrap-hero {
    width: 93%;
  }

  .step-hero {
    padding-right: 10px;
    padding-left: 10px;
  }

  .img-block {
    width: 90%;
  }

  .content {
    font-size: 20px;
  }

  .block_wrap {
    width: 100%;
  }

  .consultation-contain {
    width: 85vw;
  }

  .pricing-h1.faq-h2 {
    font-size: 28px;
    line-height: 1.3;
  }

  .faq-q-text {
    line-height: 24px;
  }

  .container.faq-container {
    max-width: 95vw;
  }

  .process-icon {
    margin-right: 0px;
    margin-bottom: 24px;
  }

  .section-process {
    padding-top: 400px;
  }

  .paragraph-standard {
    line-height: 1.5;
  }

  .card-process {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .h3-heading {
    font-size: 28px;
    line-height: 38px;
  }

  .process-icon-drawing {
    width: 100px;
    height: 100px;
    margin-bottom: 22px;
    margin-left: 15px;
  }

  .list-item {
    display: none;
  }

  .div-block {
    display: block;
    width: 90vw;
    height: 10vh;
    margin-right: auto;
    margin-left: auto;
  }

  .heading-3 {
    font-size: 22px;
    line-height: 32px;
  }

  .heading-4 {
    font-size: 22px;
    line-height: 32px;
  }

  .header-paragraph {
    font-size: 18px;
    line-height: 30px;
  }

  .header-logo-block {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .hero-heading {
    font-size: 14vw;
    line-height: 16vw;
  }

  .header-logo {
    justify-items: center;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .button-wrapper-3 {
    max-width: 260px;
    margin-right: auto;
    margin-left: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .button-header {
    margin-right: 0px;
    margin-bottom: 32px;
    text-align: center;
  }

  .hero-image-block {
    height: 340px;
  }

  .h3-heading-2 {
    font-size: 36px;
    line-height: 45px;
  }

  .paragraph-large-3 {
    font-size: 18px;
    line-height: 30px;
  }

  .content-grid {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .paragraph-small {
    text-align: left;
  }

  .feature-card-02 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
  }

  .heading-large-3 {
    font-size: 48px;
  }

  .feature-card-01 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
  }

  .feature-card-03 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
  }

  .feature-card-04 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
  }

  .hero-heading-2 {
    font-size: 15vw;
    line-height: 18vw;
  }

  .header-paragraph-2 {
    font-size: 18px;
    line-height: 30px;
  }

  .accent-wrapper-left {
    display: none;
  }

  .button-hero {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 20px;
    text-align: center;
  }

  .hero-block-2 {
    text-align: center;
  }

  .button-wrapper-4 {
    max-width: 260px;
    margin-right: auto;
    margin-left: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .header-image {
    min-height: 400px;
  }

  .add-to-cart {
    margin-top: 20px;
  }

  .button-wrapper-5 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .heading-title {
    font-size: 44px;
    line-height: 60px;
  }

  .heading-title._02 {
    font-size: 36px;
    line-height: 48px;
  }

  .arrow-large {
    margin-top: 40px;
  }

  .header-block {
    width: 100%;
  }

  .number-large {
    font-size: 28px;
  }

  .grid-content {
    margin-bottom: 120px;
  }

  .heading-wrapper-2 {
    margin-bottom: 60px;
  }

  .h1-heading-3 {
    font-size: 36px;
    line-height: 48px;
  }

  .section-large-2 {
    overflow: hidden;
  }

  .grid-button.margin-top-64 {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .heading-wrapper-4.margin-bottom-64 {
    text-align: left;
  }

  .heading-content.cta {
    font-size: 48px;
    text-align: left;
  }

  .heading-detail-section-2 {
    display: none;
    text-align: left;
  }
}

#w-node-_5cb265f0-ba3f-6b9e-224b-121d494733c9-b3bf5a67 {
  -ms-grid-column-align: start;
  justify-self: start;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-c7979273-319f-ea37-6f95-b3efced5292b-b3bf5a67 {
  -ms-grid-column-align: center;
  justify-self: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-eb836b6d-4fc5-66de-4f78-d5f47c5ffcdf-7c5ffcdc {
  -ms-grid-column-align: start;
  justify-self: start;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-eb836b6d-4fc5-66de-4f78-d5f47c5ffce2-7c5ffcdc {
  -ms-grid-column-align: center;
  justify-self: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_717ce248-0b08-9976-1a3d-5630e3fd5581-99b9931a {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_717ce248-0b08-9976-1a3d-5630e3fd5583-99b9931a {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_717ce248-0b08-9976-1a3d-5630e3fd5585-99b9931a {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_717ce248-0b08-9976-1a3d-5630e3fd558e-99b9931a {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_717ce248-0b08-9976-1a3d-5630e3fd5597-99b9931a {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_717ce248-0b08-9976-1a3d-5630e3fd55a0-99b9931a {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_717ce248-0b08-9976-1a3d-5630e3fd55a9-99b9931a {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_717ce248-0b08-9976-1a3d-5630e3fd55b2-99b9931a {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_9312d99f-58d1-3562-14a2-2e11aca342a9-99b9931a {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_94e45dc9-da3d-9b7e-83f6-cc0af25309ef-99b9931a {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_2b2b7e10-9d4a-28e5-becf-cfd473b43da5-99b9931a {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_2b2b7e10-9d4a-28e5-becf-cfd473b43d96-99b9931a {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_96fb01a7-d620-3a46-f219-393390db0bf6-99b9931a {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_2b2b7e10-9d4a-28e5-becf-cfd473b43d87-99b9931a {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_717ce248-0b08-9976-1a3d-5630e3fd5581-f851d42d {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_717ce248-0b08-9976-1a3d-5630e3fd5583-f851d42d {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_717ce248-0b08-9976-1a3d-5630e3fd5585-f851d42d {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_717ce248-0b08-9976-1a3d-5630e3fd558e-f851d42d {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_717ce248-0b08-9976-1a3d-5630e3fd5597-f851d42d {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_717ce248-0b08-9976-1a3d-5630e3fd55a0-f851d42d {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_717ce248-0b08-9976-1a3d-5630e3fd55a9-f851d42d {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_717ce248-0b08-9976-1a3d-5630e3fd55b2-f851d42d {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_9312d99f-58d1-3562-14a2-2e11aca342a9-f851d42d {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_94e45dc9-da3d-9b7e-83f6-cc0af25309ef-f851d42d {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_2b2b7e10-9d4a-28e5-becf-cfd473b43da5-f851d42d {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_2b2b7e10-9d4a-28e5-becf-cfd473b43d96-f851d42d {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_96fb01a7-d620-3a46-f219-393390db0bf6-f851d42d {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_2b2b7e10-9d4a-28e5-becf-cfd473b43d87-f851d42d {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

.navbar__parent {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
}

@media screen and (max-width: 991px) {
  #w-node-da873c82-97fc-eeb0-e88a-7b35f1ff114e-99b9931a {
    -webkit-box-ordinal-group: -9998;
    -webkit-order: -9999;
    -ms-flex-order: -9999;
    order: -9999;
  }
  .navbar__parent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-inline: 42px;
    height: 100%;
  }

  #w-node-_982c9895-d75d-30ce-1c6c-ea1b5e7e0a74-99b9931a {
    -webkit-box-ordinal-group: -9998;
    -webkit-order: -9999;
    -ms-flex-order: -9999;
    order: -9999;
  }

  #w-node-_141591e0-dc24-6b1f-748a-9bbb9e2894da-99b9931a {
    -webkit-box-ordinal-group: -9998;
    -webkit-order: -9999;
    -ms-flex-order: -9999;
    order: -9999;
  }

  #w-node-da873c82-97fc-eeb0-e88a-7b35f1ff114e-f851d42d {
    -webkit-box-ordinal-group: -9998;
    -webkit-order: -9999;
    -ms-flex-order: -9999;
    order: -9999;
  }

  #w-node-_982c9895-d75d-30ce-1c6c-ea1b5e7e0a74-f851d42d {
    -webkit-box-ordinal-group: -9998;
    -webkit-order: -9999;
    -ms-flex-order: -9999;
    order: -9999;
  }

  #w-node-_141591e0-dc24-6b1f-748a-9bbb9e2894da-f851d42d {
    -webkit-box-ordinal-group: -9998;
    -webkit-order: -9999;
    -ms-flex-order: -9999;
    order: -9999;
  }
}

@media screen and (max-width: 1000px) {
  .heading-title {
    font-size: 50px;
    line-height: 64px;
  }

  #w-node-_5cb265f0-ba3f-6b9e-224b-121d494733c9-b3bf5a67 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-c7979273-319f-ea37-6f95-b3efced5292b-b3bf5a67 {
    -ms-grid-column-align: center;
    justify-self: center;
    display: none;
  }
  .right__side__links {
    display: none;
  }

  #w-node-eb836b6d-4fc5-66de-4f78-d5f47c5ffcdf-7c5ffcdc {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-eb836b6d-4fc5-66de-4f78-d5f47c5ffce2-7c5ffcdc {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_717ce248-0b08-9976-1a3d-5630e3fd55a9-99b9931a {
    -webkit-box-ordinal-group: 10000;
    -webkit-order: 9999;
    -ms-flex-order: 9999;
    order: 9999;
  }

  #w-node-_717ce248-0b08-9976-1a3d-5630e3fd55a9-f851d42d {
    -webkit-box-ordinal-group: 10000;
    -webkit-order: 9999;
    -ms-flex-order: 9999;
    order: 9999;
  }
}
.field-main {
  border-radius: 8px;
  border: 1px solid rgba(204, 204, 204, 0.6);
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.05);
  width: 415px;
}
.in-field,
.in-field:focus,
.in-field:active,
.in-field:focus-visible {
  border: none;
  outline: none;
  padding: 10px;
  width: 200px;
  /* font-size: 12px; */
}
.pricing-h1 {
  text-align: center;
}

.pricing-h1.faq-h2 {
  margin-bottom: 50px;
  font-family: Inter, sans-serif;
  color: #6568e6;
  font-size: 22px;
  font-weight: 600;
}
