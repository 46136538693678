.overlay {
  display: none;
}
@media only screen and (max-width: 1000px) {
    .overlay {
      display: flex;
      flex-direction: column;
      justify-content: center;
      background-color: #fff;
      position: fixed;
      top: 0;
      left: 50%;
      transform: translate(-50%, 0);
      overflow: hidden;
      z-index: 1000;
      width: 100%;
      height: 100%;
    }
    .overlay__text {
      display: block;
      justify-content: center;
      text-align: center;
      color: #000;
      background: #eeeeee;
      padding: 30px;
      border: 1px solid #dadada;
      box-shadow: 0px 0px 5px 0px #d6d6d6 inset;
      width: fit-content;
      margin: 0 auto;
      margin-top: 30px;
    }
    .overlay__image {
      margin: 0 auto;
    }
  }