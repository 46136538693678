.loader {
    border: 5px solid #f3f3f3;
    border-radius: 100%;
    border-top: 5px solid black;
    width: 50px;
    height: 50px;
    position: absolute;
    animation: spin 2s linear infinite;
    /* z-index: 100000; */
  }
  
  /* Safari */
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.loader {
    top: 33%;
    left: 41%;
    z-index: 1000;
}

/* #resarvation-loader {
    left: 32rem;
} */

.take-user-details-loader {
    top: 41% !important;
    left: 43% !important;
}
.take-user-details-loader-wrapper {
    background: #00000054;
    top: 0;
    left: 0;
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 1;
}
.accept-loader{
    border: 5px solid #6568E6 !important;
    border-top: 5px solid #f3f3f3 !important;
}
.reservation-loader {
    border: 5px solid #f3f3f3;
    border-radius: 100%;
    border-top: 5px solid black;
    width: 20px;
    height: 20px;
    position: absolute;
    animation: spin 2s linear infinite;
    /* z-index: 100000; */
}
  
  /* Safari */
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* .reservation-loader {
    top: 33%;
    left: 41%;
    z-index: 1000;
} */