.resarvation-card {
  display: grid;
  align-content: center;
  align-items: center;
  grid-template-columns: auto auto auto;
  width: 960px;
  margin-top: 37px;
  border: 1px solid black;
  border-radius: 5px;
  padding: 39px 41px;
  grid-gap: 46px;
}
.resarvation-card button {
  width: 183px !important;
  height: 48px !important;
}
.resarvation-card h1 {
  font-size: 18px !important;
  line-height: 22px !important;
  margin-bottom: 19px !important;
  font-weight: bolder !important;
}
.resarvation-card p {
  font-size: 14px;
  line-height: 17px;
}

.resarvation-card-content {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 5px;
}

.resarvation-card-content-title {
  font-weight: bolder;
}

.resarvation-card-button > button {
  background: #6568e6 !important;
  border-radius: 4px;
  color: white;
  width: 183px;
  height: 48px;
}

.reservation-details div > .close-button {
}
.reservation-details div > .resarvation-space-image > img {
  width: 100%;
  height: 100%;
}
.reservation-details div > .resarvation-space-image {
  width: 160px;
  height: 160px;
}
.reservation-details div > .resarvation-space-information h2 {
  margin-top: 0px;
}
.reservation-details div > .resarvation-space-details {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  align-content: center;
}
.reservation-details > div div {
  padding: 10px;
}
.reservation-details > div {
  background: #ffffff;
  border-radius: 4px;
  width: 800px;
  padding: 60px;
  height: auto;
  position: relative;
}
.reservation-details {
  position: absolute;
  /* right: 0rem; */
  background-color: rgba(0, 0, 0, 0.262);
  height: auto;
  padding: 20px;
  top: 11rem;
  left: 15.5%;
  /* left: 26.2rem; */
  bottom: -316rem;
  width: 84.5%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  align-content: center;
}

.resarvation-information-group {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: baseline;
}

.renter-information-group > .renter-image {
  width: 156px;
  height: 156px;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  background: #e6f0fe;
  margin-right: 20px;
  border-radius: 100%;
}
.renter-information-group {
  display: flex;
}

.renter-information-data-group > h4 {
  margin-top: 0 !important;
}
.renter-information-data-group {
  display: grid;
  grid-template-columns: 1fr 2fr;
  padding: 0 !important;
  align-items: center;
  justify-items: start;
  gap: 25px;
}
.fs-16px{
  font-size: 16px;
}
.fw-bold{
  font-weight: bold;
}
.reservation-price-information{
  margin-top: 30px;
}
.reservation-price-information-data-group,
.total-calculation {
  display: grid;
  grid-template-columns: 5fr 2fr;
  padding: 0 !important;
  align-items: center;
  justify-items: start;
}
.resarvation-confirm-cancel-button-group > button {
  width: 183px;
  height: 48px;
  border-radius: 4px;
}
.resarvation-confirm-cancel-button-group {
  display: flex;
  justify-content: space-between;
}

.resarvation-confirm-cancel-button-group .reservation-cancel,
.confirm-reservation-box-button {
  color: #6568e6;
  background: white;
  border: 1px solid #6568e6;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.confirm-reservation-box-button {
  padding: 10px;
}
.resarvation-confirm-cancel-button-group .reservation-confirm {
  color: white;
  background: #6568e6;
  border: 1px solid #6568e6;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.resarvation-information-text-area textarea {
  background: #f3f3f3;
  width: 520px;
  height: 387px;
  border: none;
  padding: 20px;
}
.close-button {
  background: lightgray;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  padding: 2px;
  position: absolute;
  right: 20px;
  top: 30px;
}

.confirm-reservation-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  align-content: center;
  gap: 20px;
}

.confirm-cancel-reservation-box {
}
.confirm-cancel-reservation-form h4 {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  margin-left: 20px;
}
.confirm-cancel-reservation-form p {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #656369;
  margin-left: 20px;
}
.confirm-cancel-reservation-form {
}
.confirm-cancel-reservation-box {
}
.confirm-cancel-reservation-box img {
  margin-left: 250px;
  margin-bottom: 50px;
}

.confirm-cancel-reservation-reason-button-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.reservation-cancel-confirm {
  width: 108px;
  height: 38px;
  background: #b4b4b4;
  border-radius: 4px;
  color: white;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.reservation-cancel-notconfirm {
  color: #6568e6;
  background: white;
  border: 1px solid #6568e6;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 38px;
}

.cancel-confirm-form-reason-select-box {
}
.confirm-cancel-reservation-reason > label {
  margin-top: 3px;
}
.confirm-cancel-reservation-reason {
  display: flex;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
}
.confirm-cancel-message-reason textarea {
  width: 637px;
  height: 451px;
  background: #f3f3f3;
  border-radius: 8px;
  border: none;
  padding: 20px;
}
.confirm-cancel-message-reason {
}
