.price__parent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 0.2s ease-in-out all;
}
.clear_text {
  cursor: pointer;
  font-size: 12px;
}
.clear_text:hover {
  font-weight: 500;
  text-decoration: underline;
}
.done_btn {
  background-color: #6568e6;
  color: #fff;
  width: 210px;
  height: 40px;
  margin-top: 20px;
  font-size: 18px;
  cursor: context-menu;
  transition: cubic-bezier(0.52, 0.03, 0, 1.44) 0.3s all;
}
.done_btn span {
  font-size: 14px;
}
.done_btn span::after {
  content: '\2713';
  background: none;
  font-size: 18px;
  position: absolute;
  margin-top: 1.4px;
  padding-left: 2.5px;
  opacity: 0;
  transition: 0.5s;
}
.done_btn:hover {
  background-color: #5153d5;
}
.done_btn:hover span::after {
  opacity: 1;
}
