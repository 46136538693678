.text-area {
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  width: 470px;
  height: 150px;
  resize: none;
  text-indent: 10px;
  padding: 10px 0;
  font-size: 14px;
  transition: 0.2s ease-out all;
}
.text-area:hover {
  outline: #6568e6 1px solid;
}
.text-area:focus {
  outline: #6568e6 1px solid;
  box-shadow: 0px 0px 5px #6568e6;
}
