.booking-process {
  display: flex;
  justify-content: space-around;
}
.booking-process-text {
  color: #1b7e69;
  font-size: 20px;
}
.two-part {
  display: flex;
  justify-content: space-around;
}
.para {
  width: 500px;
  font-size: 16px;
}
.info-titles {
  font-weight: 700;
  margin-top: 15px;
  font-size: 18px;
}
.info-titles-icon {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}
.info-titles-icon img {
  margin: 5px 0;
}
.info-titles-icon h7 {
  margin-left: 5px;
}
.detail {
  color: #6568e6;
  padding-top: 5px;
  font-weight: 600;
  font-size: 16px;
}
.boxes {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.box {
  margin: 15px;
  box-shadow: rgba(128, 128, 128, 0.211) 1px 1px 10px;
  padding: 20px;
  width: 340px;
  border-radius: 5px;
  padding: 20px;
}
.box:hover {
  cursor: pointer;
  transform: scale(1.05);
  transition: ease-out 0.2s all;
}
.box p {
  width: 280px;
  height: 100px;
  font-size: 14px;
}
.main-modal {
  position: absolute;
  top: -300px;
  /* margin-left: auto;
  margin-right: auto; */
  /* width: 100vw; */
}
.info-data {
  font-size: 16px;
}
