.static_text {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 30px;
}
.space-title {
  font-size: 28px;
  font-weight: 500;
}
.space {
  border-bottom: 2px solid #d9d9d9;
  margin-top: 50px;
  width: 746px;
}
.chat-title {
  color: #6568e6;
  font-size: 18px;
  font-weight: 600;
}
.single-msg {
  display: flex;
  align-items: center;
  border-bottom: 2px solid #d9d9d9;
  /* justify-content: space-between;
  margin-top: 25px;
  padding-bottom: 25px;*/
}
.user-title {
  font-size: 16px;
  font-weight: 600;
}
.user-msg {
  width: 634px;
  font-size: 16px;
  font-weight: 500;
  margin-top: -7px;
}
.msgbox {
  border-radius: 8px;
  background: #f7f7f7;
  width: 646px;
  padding-bottom: 140px;
  resize: none;
  font-size: 14px;
  color: black;
  text-indent: 15px;
  padding-top: 10px;
  outline: #ccc 1px solid;
}
.msgbox:hover {
  outline: #6568E6 1px solid;
}
.msgbox:focus {
  outline: #6568E6 1px solid;
  box-shadow: 0px 0px 5px #6568E6;
}