.registration-form-wrapper {
  position: fixed;
  background: #00000075;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
.close-registration-form-cart-button {
  cursor: pointer;
}
.close-registration-form-cart-button img:hover {
  opacity: 0.5;
}

.ragistration-form-cart {
  background: #ffffff;
  border-radius: 8px;
  width: 680px;
  overflow-y: auto;
  overflow-x: hidden;
}

/* hide the div scrollbar on overflow: auto */
.ragistration-form-cart::-webkit-scrollbar {
  /* for Chrome, Safari, and Opera */
  /* display: none; */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.ragistration-form-cart-title {
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  color: #000000;
}
/* .ragistration-form-cart-header > div {
  padding: 20px 36px;
}
.ragistration-form-cart-header > div:nth-child(2) {
  padding-left: 166px;
} */
.ragistration-form-cart-header {
  border-bottom: 1px solid #cccccc;
  display: flex;
  justify-content: space-between;
  padding-inline: 20px;
  padding: 20px;
}
.take-user-role p {
  text-align: center;
}
.take-user-role > div:first-child {
  border-right: 1px solid #cccccc;
}
.take-user-role > div {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 32px;
}
.take-user-role {
  /* padding: 48px; */
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.select-host-option-button {
  background: #6568e6;
  color: white;
}
.select-guest-option-button {
  border: 1px solid #6568e6;
  color: #6568e6;
}
.role-option-select-button {
  cursor: pointer;
  display: flex;
  align-content: center;
  align-items: center;
  justify-items: center;
  justify-content: center;
  border-radius: 4px;
  margin: 10px 0;
  box-sizing: border-box;
  text-align: center;
  width: 276px;
  height: 48px;
}

.take-user-email-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 13px 137px;
  width: 616px;
  height: 48px;
  background: #6568e6;
  border-radius: 4px;
  margin: 16px 0px;
  cursor: pointer;
}

.login-with-google-button p {
  padding-right: 100px;
}
.login-with-google-button {
  width: 616px;
  height: 56px;
  background: #ffffff;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 8px;
  margin: 48px 0px;
  display: grid;
  grid-template-columns: 1fr 5fr;
  align-content: center;
  align-items: center;
  justify-items: center;
  justify-content: center;
  cursor: pointer;
}

.take-user-email input {
  align-items: center;
  padding: 0px 0px 0px 16px;
  width: 616px;
  height: 56px;
  background: #ffffff;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 8px;
  margin: 16px 0px;
}
.take-user-email h1 {
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #0e1111;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 12px 0px;
}
.take-user-email {
  padding: 48px 32px;
}

.devider p {
  margin: 0px 24px;
}
.devider hr {
  width: 300px;
}
.devider {
  display: flex;
  align-content: center;
  align-items: center;
  justify-items: center;
  justify-content: center;
  margin: 38px 0px;
}

.take-user-details > form > button {
  color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 13px 137px;
  width: 616px;
  height: 48px;
  background: #6568e6;
  border-radius: 4px;
  margin: 32px 0px;
}
.take-user-details > form > p {
  margin: 8px 0px 32px 0px;
  font-size: 14px;
  line-height: 17px;
  color: #808080;
}
.take-user-details {
  position: relative;
  padding: 48px 32px;
}
.final-notes {
  font-weight: normal;
  font-size: 12px;
  line-height: 17px;
  color: #000000;
}

.take-user-details .input-field {
  position: relative;
  width: 616px;
  height: 56px;
  line-height: 44px;
}
.take-user-details label {
  position: absolute;
  top: 23px;
  left: 25px;
  width: 100%;
  color: #d3d3d3;
  transition: 0.2s all;
  cursor: text;
  font-size: 16px;
  line-height: 19px;
}
.take-user-details input {
  width: 100%;
  border: 0;
  outline: 0;
  padding: 16px 0px 0px 13px;
  background: #ffffff;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  box-shadow: none;
  color: #111;
  border-radius: 8px;
}
#region,
#firstName {
  border-radius: 8px 8px 0px 0px !important;
}
#number,
#lastName {
  border-radius: 0px 0px 8px 8px !important;
}
.take-user-details input:invalid {
  outline: 0;
}
/* .take-user-details input:focus,
.take-user-details input:valid {
    /* border-color: #00dd22; */
/* } */
.take-user-details input:focus ~ label,
.take-user-details input:valid ~ label {
  font-size: 12px;
  line-height: 15px;
  top: 10px;
  left: 15px;
}

.confirm-email p {
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #0e1111;
}
.confirm-email h1 {
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #0e1111;
}
.confirm-email {
  height: 244px;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
}
.step-count {
  margin: 24px 0px 16px 0px;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  color: #0e1111;
}
.confirm-email-later {
  margin: 48px 0px 48px 0px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  text-decoration-line: underline;
  color: #000000;
  cursor: pointer;
}
.take-user-phone-number-submit-button {
  background-color: #cccccc !important;
}

.region_option_reveal_down_errow {
  position: absolute;
  right: 22px;
  bottom: 18px;
  cursor: pointer;
}

.take-user-phone-heading p {
  font-size: 14px;
  line-height: 17px;
  color: #0e1111;
}
.take-user-phone-heading h1 {
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #0e1111;
}
.take-user-phone-heading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  align-items: center;
  margin-bottom: 48px;
}
.upload-profile-photo-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 13px 137px;
  width: 616px;
  height: 48px;
  border: 1px solid #6568e6;
  color: #6568e6;
  box-sizing: border-box;
  border-radius: 4px;
  margin: 32px 0px;
  cursor: pointer;
}
.take-profile-picture img {
  margin-top: 35px;
  margin-bottom: 35px;
}
.take-profile-picture p {
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #0e1111;
  margin: 8px 0px;
}
.take-profile-picture h1 {
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #0e1111;
  margin: 8px 0px;
}
.take-profile-picture {
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  align-items: center;
  padding: 48px;
}

.confirm-email-later-phto-upload {
  margin-top: 32px !important;
}
