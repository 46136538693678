@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLDz8V1s.ttf)
    format('truetype');
}
.react-calendar__navigation__label__labelText--from {
  font-size: 16px;
  margin-left: 90px;
}
.react-calendar__navigation__prev-button {
  display: none;
}
.react-calendar__navigation__prev2-button {
  display: none;
}
.react-calendar__navigation__next2-button {
  display: none;
}
.react-calendar__navigation__next-button {
  transform: scale(1.8);
  margin-left: 50px !important;
  align-self: flex-start;
}
.react-calendar__month-view__days__day {
  color: #656369 !important;
}
.react-calendar__month-view__days__day--weekend {
  flex: 0;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-center-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.bordered {
  border: 1.5px solid rgba(179, 210, 255, 0.25);
}
/* * { */
/* margin: 0; */
/* padding: 0; */
/* font-family: 'Poppins', sans-serif; */
/* scroll-behavior: smooth; */
/* font-weight: 500; */
/* } */
.react-calendar {
  width: 325px;
  /* position: absolute; */
  /* max-height: 400px !important; */
  /* background: linear-gradient(#02041bc9, #0d0b25e1); */
  /* backdrop-filter: blur(20px); */
  /* background: #fff; */
  /* border: 1px solid #D9D9D9; */
  font-family: 'Poppins', sans-serif;
  font-family: 'Poppins';
  color: #0d0119;
  line-height: 18px;
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -8px;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 8px;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 8px;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  /* height: 44px;
  margin-bottom: 1em; */
  text-align: center;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}
.react-calendar__navigation button[disabled] {
  color: #656369;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #006edc00 !important;
  text-decoration: underline;
}
.react-calendar__navigation__label {
  color: #fff;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: capitalize;
  margin-bottom: 8px;
  font-weight: bold;
  font-size: 0.75em;
  width: 320px;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers {
  font-weight: bold;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}
.react-calendar__month-view__days__day--weekend {
  color: #656369 !important;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}
.react-calendar__month-view__days__day--weekend {
  border-radius: 100%;
  max-width: 35px !important;
}
.react-calendar__month-view__days__day {
  border-radius: 100%;
  max-width: 35px !important;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: 0.75em 0.5em;
  background: none;
  color: #000;
}
.react-calendar__tile:disabled {
  text-decoration: underline;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #6568e6;
  columns: #fff;
}
.react-calendar__navigation button {
  color: #000;
  padding-top: 14px;
}
.react-calendar__tile--now {
  background: #6568e6;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ffffa9;
}
.react-calendar__tile--hasActive {
  background: #76baff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}
.react-calendar__tile--active {
  background: #6568e6;
  color: white !important;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #6568e6 !important;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}
