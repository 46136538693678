.review-text {
  color: #4bbe88;
  max-width: 200px;
}
/* .review-text:hover {
  text-decoration: underline;
} */
.see-detail-btn:hover {
  opacity: 0.7;
}
.no__upcoming__bookings {
  display: flex;
  justify-content: space-between;
  border: #ccc 1px solid;
  margin: 25px 0px;
  border-radius: 16px;
  align-items: center;
}
.show-more {
  color: #999;
  cursor: pointer;
  margin-left: 5px;
}
.show-more:hover {
  text-decoration: underline;
}
.boxes h4 {
  font-size: 20px;
  font-weight: 500;
}