.msg__frame {
  display: flex;
  justify-content: space-between;
  padding-inline: 25px;
  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 5px;
  width: 1100px;
  align-items: center;
  padding-block: 22px;
}
.msg__frame:hover {
  background-color: rgb(249, 249, 249);
  cursor: pointer;
}
.title__1 {
  font-size: 16px;
  font-weight: 500;
}
.title__2 {
  font-size: 16px;
  font-weight: 500;
  color: #656369;
  width: 450px;
}
.title__3 {
  font-size: 16px;
  font-weight: 500;
  color: #b4b4b4;
}
.user-title {
  font-size: 16px;
  font-weight: 600;
  width: 150px;
}
.title__4 {
  font-size: 18px;
  font-weight: 500;
  text-align: right;
}
.user-title {
  margin-top: 10px;
}
