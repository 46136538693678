@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');
* {
  font-family: 'Inter';
  /* font-weight: 500; */
  font-size: 12px;
  /* ! font-size comment out causing multiple style conflicts, it it should be commented and put the font-size whenever needed */
}
.search-bar {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: #cccccc 1px solid;
  width: 300px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.07) 2px 2px 10px;
  font-family: 'Inter', sans-serif;
}
.log__Btns {
  margin: 12px;
}
.log__Btns:hover {
  text-decoration: underline;
  cursor: context-menu;
}
.search-bar h3 {
  font-size: 14px;
  margin: 0 15px;
  cursor: context-menu;
  user-select: none;
}
.arrow-btns {
  display: flex;
  justify-content: space-between;
  margin: 32vh 20px;
  user-select: none;
}
.arrow-btns img {
  width: 45px;
  cursor: context-menu;
}
.arrow-btns img:hover {
  opacity: 0.7;
}
.carousel-bg {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 85vw;
  height: 78vh;
  transition: ease-in-out 0.2s all;
  position: relative;
}
.dots-container-40 {
  position: absolute;
  margin: -30px 0;
  width: 85vw;
}
.dots {
  display: flex;
  justify-content: center;
}
.header {
  display: flex;
  justify-content: space-around;
  margin-top: 22px;
  /* margin-left: 250px; */
}
.grp-clk-scale {
  display: flex;
}
.grp-clk-scale > div {
  display: flex;
  align-items: center;
  margin: 0px 6px;
}
.grp-clk-scale h4 {
  margin: 0px 5px;
}
.gmap_canvas {
  overflow: hidden;
  background: none !important;
  height: 500px;
  width: 600px;
}
.mapouter {
  position: relative;
  text-align: right;
  height: 500px;
  width: 600px;
}
.leftbar {
  width: 600px;
}
.rightbar {
  box-shadow: #0000001d 0px 0px 10px;
  border-radius: 10px;
  padding: 20px;
  height: max-content;
  position: sticky;
  top: 2%;
}
.prices {
  margin-top: 30px;
}
.stuff-prices {
  display: flex;
  justify-content: space-between;
  margin: 10px 0px;
}
.booking-btn {
  background-color: #6568e6;
  border: none;
  margin-top: 10px;
  color: #fff;
  font-size: 20px;
  padding: 10px 16px;
  border-radius: 6px;
  width: 100%;
  font-family: 'Inter';
  font-weight: 500;
  font-size: 14px;
}
.btn-outline {
  border: #6568e6 1px solid;
  background-color: #fff;
  color: #6568e6;
}
.btn-outline:hover {
  background-color: #fff;
  color: #fff;
}
.booking-btn:hover {
  background-color: #5658e0;
  cursor: pointer;
  transition: ease-in-out 0.3s all;
}
.date-dropdown {
  display: flex;
  justify-content: space-between;
  border: #ccc 1px solid;
  border-radius: 10px 10px 0px 0px;
  align-items: center;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  transition: ease-out 0.2s all;
  padding: 0 20px;
  padding-right: 30px;
  transition: 0.2s ease-out all;
}
.date-dropdown:hover {
  background-color: rgba(204, 204, 204, 0.205);
}
.start-end-date {
  display: flex;
  justify-content: center;
  width: 320px;
}
.start-end {
  display: flex;
  justify-content: space-around;
  border: #ccc 1px solid;
  align-items: center;
  width: 50%;
  transition: ease-in-out 0.2s all;
}
.start-end:hover {
  background-color: #f4f4f495;
}
.start-end-date:hover {
  cursor: pointer;
}
.operation-hours {
  margin: 20px 0;
}
.operation {
  display: flex;
  margin-left: -15px;
}
.operation h5 {
  color: #868686;
  width: 80px;
  margin: 0 20px;
  font-family: 'Inter';
}
.date-menu {
  position: absolute;
  background-color: #fff;
  box-shadow: #ccc 2px 2px 5px;
  border-radius: 0px 0px 10px 10px;
  margin-top: 55px;
  margin-left: -30px;
  display: flex;
  flex-direction: column;
  height: 450px;
  border: #ccc 1px solid;
  overflow-y: scroll;
  transition: ease-in-out 0.2s all;
  text-align: center;
}
.time-text {
  font-size: 16px;
  margin-left: -88px;
  padding: 10px 78px;
}
.time-text:hover {
  background-color: rgba(204, 204, 204, 0.3);
  border-radius: 4px;
  cursor: pointer;
}
.date-btn {
  display: flex;
  flex-direction: column;
}
.label-start-end-date {
  font-size: 16px;
  cursor: pointer;
}
.img__changer__arrow {
  width: 60px;
  object-fit: contain;
  /* border: 1px rgba(255, 255, 255, 0.2) solid; */
  border-radius: 100%;
}
.img__changer__arrow:hover {
  opacity: 0.7;
}
.img__changer__arrow:active {
  opacity: 0.4;
}
.cover__img__pane:hover {
  transform: scale(1.3);
  border: 1px #555 solid;
  transition: all ease-in 0.2s;
}
.photo__modal__backdrop {
  top: 0;
  left: 0;
  background-color: #000000cd;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 5;
  transition: ease-in-out 0.2s all;
}
.photo__modal__img {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.85);
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-repeat: no-repeat;
}
