.space-setup-inserte-activities-last-div {
  margin-bottom: 37px;
  width: 1035px;
  border: 1px solid #bababa;
  margin-top: 117px;
}
.activities-box ul {
  list-style: none;
  margin: 0px 30px 15px 23px;
  padding: 0;
}
.activities-box h2 {
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  /* text-align: center; */
  margin-left: 23px;
}
.downarrow {
  margin-left: 35%;
}
.space-setup-inserte-activities-form {
}
.activities-box {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 34px;
  grid-gap: 34px;
  gap: 34px;
  justify-content: start;
  align-items: start;
}
.activitie > span {
  margin-left: 15%;
}
.activitie hr {
  width: 168px;
  border: 1px solid #bababa;
  margin-left: 23px;
}
.activitie {
  width: 322px;
  background: #ffffff;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 60px 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.activitie ul li {
  margin-top: 18px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.activitie > h1 {
  width: 81px;
  font-weight: bold;
  font-size: 28px;
  line-height: 40px;
  color: #000000;
  margin-bottom: 26px;
  padding: 0px 23px;
}
.activitie > button {
  margin-left: 23px;
  background: #c9c9c9;
  color: white;
  width: 171px;
  height: 35px;
  border: none;
}
.activitie span {
}
.activitie hr {
}
.activitie img {
  cursor: pointer;
}

.space-emnities-form {
}
.space-emnities-form p {
  width: 447px;
  height: 17px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}
.space-emnities-form h1 {
  width: 530px;
  height: 29px;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
}
.list-of-emnities-box {
  padding: 44px;
  border: 1px solid black;
  width: 78%;
  margin-top: 58px;
}
.emnity-box:last-child {
  border: none;
}
.emnity-box {
  padding: 25px 0px 25px 0px;
  border-bottom: 1px solid #bababa;
  margin-bottom: 50px;
}
.emnity-box h1 {
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  color: #000000;
}
.emnity-box button {
  width: 63px;
  height: 41px;
  margin-left: 10px;
  margin-top: 18px;
  background: #e6f0ff;
  border: 0.5px solid #b8b7b7;
  box-sizing: border-box;
  border-radius: 3px;
}
.emnity-box input {
  width: 165px;
  height: 41px;
  margin-top: 18px;
  background: #ffffff;
  border: 0.5px solid #b8b7b7;
  box-sizing: border-box;
  border-radius: 3px;
}
.child-emnities {
  margin-top: 28px;
  margin-bottom: 28px;
  position: relative;
  display: flex;
  gap: 20px;
  flex-direction: row;
  flex-wrap: wrap;
}
.child-emnity {
  text-align: center;
  padding: 12px 24px;
  background: #f7f7f7;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
}

.emnity {
}

.selected-button::before {
  content: '✔️';
}
.selected-button {
  color: #000000 !important;
  background: #e6f0ff !important;
  border: 0.5px solid #000000 !important;
  box-sizing: border-box !important;
  border-radius: 3px !important;
}

.event-form {
}
.price-input-box-group {
  position: relative;
}
.price-input-box-group > input {
  width: 201px;
  height: 41px;
  background: #ffffff;
  border: 0.5px solid #000000;
  box-sizing: border-box;
  border-radius: 3px;
  margin-top: 25px;
  padding: 0px 10px;
}
.price-input-box-group > span {
  width: 201px;
  height: 41px;
  background: #ffffff;
  border: 0.5px solid #000000;
  box-sizing: border-box;
  border-right: none;
  border-radius: 3px 0px 0px 3px;
  padding: 10px;
  margin-top: 25px;
}
.cleaning-fee-conditions-group label {
  width: 205px;
  height: 17px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  margin-left: 4px;
}
.pricing-conditions-group label {
  width: 78px;
  height: 17px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  margin-left: 4px;
}
.event-form h1 {
  margin-bottom: 12px;
  margin-top: 26px;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  align-items: center;
  color: #000000;
}
.event-form-hr {
  width: 268px !important;
  border: 1px solid #bababa !important;
  margin-left: 1px !important;
  margin-top: 37px !important;
  margin-bottom: 37px !important;
}

.minimum-number-of-hours > input {
  width: 201px;
  height: 41px;
  margin-top: 14px;
  background: #ffffff;
  border: 0.5px solid #000000;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 0px 10px;
}

.hour-discount > input {
  width: 201px;
  height: 41px;
  margin-top: 14px;
  background: #ffffff;
  border: 0.5px solid #000000;
  box-sizing: border-box;
  border-radius: 3px;
}

.cleaning-fee-conditions-group label {
  width: 205px;
  height: 17px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}
.meetings_classes_rehearsals_price_range_time_start,
.meetings_classes_rehearsals_price_range_time_end {
  display: grid;
  align-items: center;
  justify-items: center;
  justify-content: center;
  align-content: center;
  grid-template-columns: 3fr 1fr;
}
.meetings_classes_rehearsals_price_range_time_set {
  display: flex;
  align-items: center;
  justify-content: center;
}
.meetings_classes_rehearsals_price_range_time_start,
.meetings_classes_rehearsals_price_range_time_end,
.meetings_classes_rehearsals_price_range_time_set {
  width: 108px;
  height: 43px;
  background: #f3f3f3;
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #959595;
  padding: 5px;
}

.time-range-set {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  align-content: center;
  gap: 10px;
}
.meetings_classes_rehearsals_price_range_time_set {
  display: flex;
  flex-direction: column;
  position: relative;
  width: auto;
  padding: 10px;
  cursor: auto !important;
}
.meetings_classes_rehearsals_price_range_time_set_option_place_holder {
}
.meetings_classes_rehearsals_price_range_time_set_option > ul li > label {
  display: flex;
}
.meetings_classes_rehearsals_price_range_time_set_option > ul li {
  cursor: auto !important;
}
.meetings_classes_rehearsals_price_range_time_set_option {
  position: absolute;
  background: #ffffff;
  box-shadow: 0px 6px 12px 6px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  width: 158px;
  z-index: 10;
  top: 60px;
  left: 0px;
}
