@import url('./style/loader.css');
@import url('./style/resarvationCard.css');
@import url('./style/aboutSpaceInsertForm.css');
@import url('./style/spacePhotoInsertForm.css');
@import url('./style/availabilityOfSpaceInsert.css');
@import url('./style/switchBotton.css');
@import url('./style/activitiesOfSpaceInsert.css');
@import url('./style/policyAndRulsOfSpaceInsert.css');
@import url('./style/calendar.css');
@import url('./style/loadingAnimation.css');
@font-face {
  font-family: 'inter';
  src: url('./style/fonts/Inter-VariableFont_slnt\,wght.ttf');
}
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');
body,
html {
  margin: 0;
  padding: 0;
  font-family: 'Inter';
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}
hr {
  margin: 0;
  margin-top: 20px;
}
/* ::-webkit-datetime-edit-year-field:not([aria-valuenow]),
::-webkit-datetime-edit-month-field:not([aria-valuenow]),
::-webkit-datetime-edit-day-field:not([aria-valuenow]) {
    color: transparent !important;
} */

input[type='date']:required:invalid::-webkit-datetime-edit {
  color: transparent;
}

input[type='date']:focus::-webkit-datetime-edit {
  color: black !important;
}

.button-link {
  margin-right: 16px;
  font-size: 14px;
  line-height: 16.94px;
  font-weight: 500;
  padding: 15px 23px;
  border: 1px solid black;
  border-radius: 5px;
  color: rgba(0, 0, 0);
}

.logo,
.logo img {
  height: 51px;
  width: 105px;
}
.drop-down,
.drop-down img {
  height: 32px;
  width: 32px;
  cursor: pointer;
}

header {
  border-bottom: 1px solid #000000;
}

header {
  display: flex;
  align-items: center;
}

header nav {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
  width: 90%;
  height: 100px;
  margin: 15px 0px;
}

header nav a {
  margin: 0px 29px;
}

header nav a:last-child:hover {
  border: none;
}

header .logo {
  margin-left: 24px;
  border-radius: 100%;
}

a {
  text-decoration: none;
  color: black;
}

/* a:hover {
  color: #000000;
  font-weight: bolder;
  border-bottom: 1px solid black;
} */
/* a:active {
  border-bottom: 1px solid black;
} */
.active {
  font-weight: 700 !important;
  border-bottom: 1px solid black;
}

main aside {
  float: left;
  /* min-height: 100vh; */
  background: #ededed;
  width: 15.5%;
}
main .main-content {
  float: right;
  width: 84.5%;
}

main aside nav ul li a {
  font-size: 14px;
  line-height: 16.94px;
}

main aside nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
main aside nav ul li {
  /* padding: 45px 85px 56px 26px; */
  border-bottom: 1px solid #c4c4c4;
}

main aside .space-side-nevbar ul li a {
  font-size: 14px;
  line-height: 16.94px;
}

main aside .space-side-nevbar ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
main aside .space-side-nevbar ul > li:first-child {
  padding: 45px 0px 19px 30px;
}
main aside .space-side-nevbar ul > li {
  padding: 17px 0px 19px 30px;
  border-bottom: 1px solid #c4c4c4;
}

.main-content .banner {
  display: grid;
  align-items: center;
  background: #e8e8fb;
  grid-template-columns: 50% 50%;
  position: relative;
}

.page-number {
  position: absolute;
  /* right: 48px; */
  bottom: 20px;
  right: 15px;
}

.main-content .banner button {
  background: #6568e6;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px;
  width: 40%;
}

.banner-content h1 {
  /* font-family: Inter; */
  font-style: normal;
  /* font-weight: bold; */
  font-size: 28px;
  line-height: 34px;
  display: flex;
  align-items: center;
}

.banner-content p {
  /* font-family: Inter; */
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  margin-top: 15px;
}
h1,
p {
  margin: 0;
  padding: 0;
}

.link-button,
button {
  background: #ffffff;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 4px;
}

.content {
  margin-left: 50px;
  margin-top: 52px;
}

/* hide the div scrollbar on overflow: auto */
.content::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.content h1 {
  /* font-family: Inter; */
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: left;
}
.button-list {
  margin-top: 17px;
  margin-bottom: 40px;
  font-size: 15px;
  /* height: auto; */
  /* overflow: auto; */
  clear: both;
}

.button-list button {
  width: 183px;
  height: 48px;
  margin-right: 19px;
}
.data-showing-box {
  width: 914px;
  height: 175px;
  background: #ededed;
  border: 0.15px solid #ededed;
  box-sizing: border-box;
  box-shadow: 3px 8px 8px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.data-showing-box {
  /* font-family: Inter; */
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #a4a4a4;
}

.pageCount {
  display: flex;
  align-content: center;
  justify-content: flex-end;
  align-items: center;
  padding-top: 17px;
  padding-right: 76px;
}

.basic-information-inserte-section {
  margin-top: 59px;
}

.basic-information-inserte-section h1 {
  /* font-family: Inter; */
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;
  margin-top: 12px;
  margin-left: 37px;
}

.basic-information-inserte-section p {
  /* font-family: Inter; */
  font-style: normal;
  margin-top: 15px;
  margin-left: 37px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}
.basic-information-inserte-form {
  margin-top: 45px;
  margin-left: 37px;
  display: grid;
  grid-template-columns: auto auto;
  gap: 36px 0px;
}

.basic-information-input-group label {
  margin-bottom: 16px;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
}
.basic-information-input-group input,
.basic-information-input-group select {
  background-color: #f3f3f3;
  border: none;
  height: 43px;
  width: 90%;
  padding: 0px 10px;
}
.location-insert-submit-button,
.basic-information-input-group button {
  background: #6568e6;
  color: white;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 4px;
  width: 180px;
  height: 48px;
  margin-bottom: 70px;

}
.location-insert-submit-button {
  position: absolute;
  right: 0px;
}
.basic-information-input-group:last-child {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding-right: 90px;
}

.location-insert-map {
  margin-left: 37px;
  margin-top: 44px;
  /* height: 475px; */
  width: 693px;
  /* background-image: url('./img/map.png'); */
  position: relative;
}

.location-insert-form .basic-location-search-box input {
  border: none;
  height: 50px;
  width: 298px;
  /* position: absolute; */
  /* top: 0; */
  /* left: -15px; */
  text-align: center;
  border-radius: 4px;
  font-weight: bolder;
}

.location-insert-form .basic-location-search-box img {
  position: absolute;
  top: 7px;
  left: -33px;
}
.instructionImage {
  position: absolute;
  left: 0px;
  top: 230px;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #eaebec;
  width: 5px;
  border-left: none;
  box-sizing: border-box;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #807e7e;
  border-radius: 16px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #807e7e;
  border-radius: 16px;
}

.bar-chart {
  margin: 103px 0px 0px 50px;
}

.cart-filter-information {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  margin-left: 50px;
  margin-top: 50px;
  padding-right: 97px;
  justify-content: flex-start;
}
.total-earning-information > p {
  width: 149px;
  height: 17px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}
.total-earning-information > h1 {
  font-weight: bold;
  font-size: 48px;
  line-height: 58px;
  color: #000000;
}
.cart-filter h1 {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  align-items: center;
  color: #000000;
  margin-bottom: 20px;
}
.cart-filter {
  position: relative;
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  align-items: flex-start;
  width: 800px;
  justify-content: space-around;
  margin-left: 190px;
}

.option-cart-filter-space-box > img,
.option-cart-filter-history-box > img,
.option-cart-filter-history-second-box > img {
  margin-bottom: 10px;
  cursor: pointer;
}
.option-cart-filter-history-second-box {
  margin-top: 42px;
}
.option-cart-filter-space-box,
.option-cart-filter-history-box,
.option-cart-filter-history-second-box {
  width: 178px;
  height: 33px;
  border: 1px solid #959595 !important;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  width: 200px;
  padding: 15px;
  position: relative;
}

.export-button {
  width: 99px;
  height: 35px;
  background: #6f73b3;
  border-radius: 4px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  color: white;
  /* margin-top: 43px; */
  cursor: pointer;
  margin-left: 80px;
}

.chart-data-information > div:first-child {
  margin-left: 0px;
}
.chart-data-information > div {
  margin-left: 108px;
}
.chart-data-information {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  align-content: center;
  margin-left: 50px;
  margin-top: 51px;
}

.amount {
  width: 121px;
  height: 34px;
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
  color: #000000;
}

.information-box-color {
  width: 38px;
  height: 36px;
}

.payout-color {
  background: #348262;
}
.expected-color {
  background: #67cea3;
}
.refunds-color {
  background: #cccccc;
}
.chargebacks-color {
  background: #f6922f;
}
.amount-information-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-content: center;
  margin-top: 15px;
}

.information-box-name {
  width: 55px;
  height: 17px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}
.chart-data-information {
  margin-top: 51px;
}

.transaction-history-cart-filter-information > div {
  margin-left: 77px;
  margin-top: 70px;
}
.transaction-history-cart-filter-information {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  border-bottom: 1px solid black;
  margin-left: 31px;
  margin-right: 31px;
  padding-bottom: 63px;
  margin-bottom: 39px;
}

.transaction-history-range > div:nth-child(2) {
  margin-left: 40px;
}
.transaction-history-range {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}

.export-button-transaction-history {
  margin-top: 59px !important;
}

.transaction-history-select-option-group label {
  height: 22px !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 22px !important;
  color: #000000 !important;
  margin-bottom: 10px;
}

.transaction-amount-history > h1 {
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;
  color: #000000;
}
.transaction-amount-history > p {
  width: 100px !important;
  height: 19px !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 19px !important;

  color: #707070;
}
.left-arrow-for-table,
.right-arrow-for-table {
  height: 20px;
  width: 20px;
  border: 1px solid black;
  padding: 5px;
  cursor: pointer;
  margin-left: 5px;
}
.left-arrow-for-table {
  transform: rotate(90deg);
}

.right-arrow-for-table {
  transform: rotate(271deg);
}
.transaction-amount-history div:last-child {
  display: flex;
  align-content: flex-end;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}
.transaction-amount-history div {
  text-align: center;
}
.transaction-amount-history {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.transaction-history-table {
  width: 95%;
  margin: 61px 43px 0px 43px;
}
.transaction-history-table th:first-child {
  border-left: none;
}
.transaction-history-table th:last-child {
  border-right: none;
}
.transaction-history-table td:last-child {
  border-right: none;
}
.transaction-history-table td {
  border-bottom: 1px solid #cccccc;
  border-right: 1px solid black;
  padding: 20px;
}
.transaction-history-table th {
  text-align: center;
  border-right: 1px solid black;
  border-left: 1px solid black;
  border-bottom: 1px solid black;
  border-top: 1px solid #cccccc;
  padding: 15px;
}

.discountCoupon p {
  font-size: 16px;
  line-height: 19px;
}
.discountCoupon span {
  font-size: 16px;
  line-height: 19px;
}
.discountCoupon b {
  font-size: 16px;
  line-height: 19px;
}
.discountCoupon i {
  font-size: 16px;
  line-height: 19px;
}
.discountCoupon > i {
  font-size: 16px;
  line-height: 19px;
}
.payout-details-wrapper > p {
  width: 799px;
  height: 19px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  margin-top: 15px;
}
.payout-details-wrapper h1 {
  width: 308px !important;
  height: 34px !important;
  font-weight: 500 !important;
  font-size: 28px !important;
  line-height: 34px !important;
  color: #000000 !important;
}
.payout-details-wrapper form > button {
  align-items: center;
  width: 183px;
  height: 48px;
  background: #6568e6;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 4px;
  color: white;
  margin-top: 20px;
}
.payout-details-wrapper form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 18px;
  width: 458px;
}

.payout-details-input-group:nth-child(1),
.payout-details-input-group:nth-child(6) {
  grid-column: 1/3;
}
.payout-details-wrapper {
  margin: 39px 0px 0px 48px;
}
.cash-chacking-button {
  margin: 21px 0px 39px 0px;
  width: 398px;
  height: 57px;
  background: #ffffff;
  border: 1.5px solid #000000;
  box-sizing: border-box;
  box-shadow: 2px 5px 7px rgb(0 0 0 / 10%);
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  cursor: pointer;
}
.payout-details-input-group label {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  color: #000000;
  margin-bottom: 12px;
  margin-top: 14.5px;
}
.payout-details-input-group input {
  background: #ffffff;
  border: 1px solid #e3e3e3;
  box-sizing: border-box;
  width: 100%;
  padding: 12px;
}

.payout-details-input-group {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.select_month_end {
  margin-top: 10px;
}

.select-option-list ul {
  margin: 0;
  padding: 0;
}
.select-option-list ul li {
  height: 37px;
  padding: 20px;
  list-style: none;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  align-content: center;
  flex-direction: row;
}
.select-option-list {
  position: absolute;
  background: #ffffff;
  box-shadow: 0px 6px 12px 6px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  z-index: 10000;
  width: 200px;
  margin-top: 5px;
  height: 192px;
  overflow: scroll;
  overflow-x: hidden;
}

.option-selected {
  background: #6568e6;
  color: white;
}

.select-option-item-check-box label {
  margin-left: 10px;
  cursor: pointer;
}

.transaction-history-cart-filter div {
  margin-right: 20px;
}
.transaction-history-cart-filter div h1 {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  align-items: center;
  color: #000000;
  margin-bottom: 10px;
}

.transaction-history-cart-filter {
  display: flex;
  align-items: flex-start;
  width: 100%;
  justify-content: flex-start;
}
.bank-cash-out-button {
  position: relative;
}
.bank-cash-out-button:hover {
  color: white !important;
}
.bank-cash-out-button,
.stripe-cash-out-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 260px;
  height: 48px;
  background: #6568e6;
  border-radius: 4px;
  color: white;
  cursor: pointer;
}
.bank-chash-out > img,
.stripe-chash-out > img {
  height: 34px;
  width: 34px;
}
.bank-chash-out,
.stripe-chash-out {
  display: flex;
  margin-top: 30px;
  gap: 19px;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
}

.about-space-insert-button-group p {
  width: 461px;
  height: 60px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  margin-top: 21px;
}

.active-link {
  color: #000000;
  font-weight: bold;
  border-bottom: 1px solid black;
}

.notification-container {
  width: 520px !important;
}

.photo-upload-box-button-box > input {
  position: absolute;
  cursor: pointer;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
}

.photo-upload-box-button-box {
  position: relative;
  cursor: pointer;
}

.submit-form-button {
  background: white;
  padding: 5px;
  margin-top: 10px;
}

.emnities-selected {
  border: 3px solid black;
}

.de-activated-link {
  cursor: pointer;
  color: lightgrey;
}

.de-activated-link:hover {
  cursor: pointer;
  color: lightgrey;
  border: none;
}

.profile-drop-down ul li:last-child {
  border-top: 1px solid #bababa;
  width: 100%;
  padding-top: 15px;
  text-align: left;
  padding-left: 35px;
}
.profile-drop-down ul li {
  font-size: 16px;
  line-height: 19px;
  margin-top: 10px;
  margin-bottom: 8px;
}
.profile-drop-down ul li a,
.profile-drop-down ul li {
  font-weight: normal;
}
.profile-drop-down ul {
  position: absolute;
  list-style: none;
  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 4px;
  width: 154px;
  /* height: 140px; */
  /* right: 80px; */
  /* top: 93px; */
  right: 0;
  z-index: 100;
  margin-left: 0px;
  padding: 0px;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: space-around;
  align-items: flex-start;
}

.host-profile-data-group-list p {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  margin-top: 12px;
}
.host-profile-data-group-list h3 {
  margin: 0;
  padding: 0;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
}
.host-profile-data-group-list {
  margin-top: 80px;
  margin-left: 64px;
  width: 40%;
}
.host-profile-data-group {
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 32px;
  padding-top: 32px;
}

.host-profile-data-group form {
  margin-top: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: start;
  align-items: center;
  justify-items: start;
}
.host-profile-data-group form label {
  position: absolute;
  /* top: 23.5vw; */
  z-index: 1;
  padding-left: 19px;
  padding-top: 7px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #656369;
}
.host-profile-data-group form button {
  width: 87px;
  height: 48px;
  background: #6568e6;
  border-radius: 4px;
  color: white;
  margin-top: 10px;
}
.host-profile-data-group form textarea {
  box-sizing: border-box;
  width: 383px;
  height: 134px;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
}
.host-profile-data-group form input {
  width: 120%;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  height: 59px;
  padding-left: 17px;
  padding-top: 20px;
  position: relative;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #0d0119;
}

.edit-button {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  text-align: right;
  text-decoration-line: underline;
  color: #6568e6;
  cursor: pointer;
}

.profile-picture-upload-side-bar p {
  margin: 0;
  padding: 0;
}
.profile-picture-upload-side-bar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* width: 10%; */
  height: 340px;
  border-top: 1px solid #c4c4c4;
  gap: 20px;
  font-size: 14px;
  /* position: fixed; */
  bottom: -1vw;
  /* border: 1px solid gold; */
}
.user-picture {
  margin-bottom: 30px;
}
.user-picture,
.user-email-side-bar,
.phone-number-side-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.user-picture img {
  width: 50px;
  height: 50px;
}
input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-calendar-picker-indicator {
  opacity: 0;
  -webkit-appearance: none;
}

.pop-up-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  background: #00000045;
  z-index: 2;
  right: 0;
  left: 0;
}

.pop-up-card {
  background: white;
  display: flex;
  flex-direction: column;
  height: auto;
  background: #ffffff;
  border-radius: 8px;
}

.profile-pop-up-card-header {
  display: grid;
  grid-template-columns: 1fr 10fr;
  align-items: center;
  justify-items: center;
  height: 80px;
  border-bottom: 1px solid #d9d9d9;
}
.profile-pop-up-close {
  font-size: 30px;
  cursor: pointer;
  font-weight: 100;
}

.profile-pop-up-card-body p {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  width: 448px;
  height: 40px;
  text-align: center;
  color: #0d0119;
}
.profile-pop-up-card-body h4 {
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  color: #0d0119;
}
.profile-pop-up-card-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 48px 32px;
  width: 680px;
}

.profile-pop-up-footer-button-disable-fill {
  border: 1px solid #d9d9d9;
  color: white;
  background-color: #d9d9d9;
}
.profile-pop-up-footer-button-enable-fill {
  border: 1px solid #6568e6;
  color: white;
  background-color: #6568e6;
}

.profile-pop-up-footer-button-enable-out-line {
  border: 1px solid #6568e6;
  color: #6568e6;
  background-color: white;
}

.profile-pop-up-footer-button-disable-out-line {
  border: 1px solid #d9d9d9;
  color: #d9d9d9;
  background-color: white;
}
.profile-pop-up-footer-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 13px 137px;
  gap: 10px;
  width: 442px;
  height: 48px;
  border-radius: 4px;
  cursor: pointer;
}

.host-profile-email-change-input-group > input {
  box-sizing: border-box;
  width: 616px;
  height: 56px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  padding: 16px;
}
.host-profile-email-change-input-group > p {
  text-align: left;
}
.host-profile-email-change-input-group {
  margin-bottom: 48px;
}
#change_country {
  border-radius: 8px 8px 0px 0px;
}
#change_phone_number {
  border-radius: 0px 0px 8px 8px;
}
.change-phone-number-input-group label {
  position: absolute;
  z-index: 2;
  padding-left: 17px;
  padding-top: 6px;
}
.change-phone-number-input-group input {
  width: 616px;
  height: 56px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  padding-left: 16px;
  padding-top: 20px;
}

.host-profile-view-information-box-group {
  display: flex;
}

.host-profile-view-varification-information-group {
  padding: 24px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  margin-top: 85px;
}
.host-profile-view-host-image-box {
  width: 445px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #d9d9d9;
}
.host-profile-view-host-image-box > img {
  height: 89px;
  width: 89px;
  margin: 50px;
}
.host-profile-view-email-varification-information p,
.host-profile-view-phone-varification-information p {
  padding-top: 10px;
  color: green;
  font-weight: bolder;
}
.host-profile-view-email-varification-information,
.host-profile-view-phone-varification-information {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-content: center;
  margin-top: 20px;
}
.host-profile-view-basic-information p {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #0d0119;
}
.host-profile-view-basic-information h3 {
  font-weight: 500;
  font-size: 32px;
  line-height: 39px;
  color: #0d0119;
  margin-bottom: 30px;
}
.host-profile-view-event-list-heading {
  font-weight: 500;
  font-size: 32px;
  line-height: 39px;
  color: #0d0119;
  margin-top: 117px;
}
.host-profile-view-basic-information {
  margin-top: 161px;
  margin-left: 101px;
}

.host-profile-view-event-list-heading-hr {
  width: 229px;
  margin-top: 44px;
  margin-bottom: 44px;
}

.host-profile-view-basic-information-name,
.host-profile-view-basic-information-mission {
  padding-bottom: 32px;
  border-bottom: 1px solid #d9d9d9;
}
.host-profile-view-information-event-group > h2 {
  font-weight: 500;
  font-size: 32px;
  line-height: 39px;
  color: #0d0119;
}
.host-profile-view-basic-information-mission {
  padding-top: 32px;
}
.host-profile-view-information-event-box {
  display: flex;
  width: 962px;
  height: 317px;
  background: #ffffff;
  /* border: 0.14px solid #000000; */
  box-shadow: 3px 8px 8px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  margin-bottom: 50px;
}
.host-profile-view-information-event-box-image img {
  object-fit: contain;
  width: 348px;
  height: 227px;
}
.host-profile-view-information-event-box-image {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 43px;
}

.host-profile-view-information-event-box-information h3 {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
}
.host-profile-view-information-event-box-information > button {
  background: #6568e6;
  border: 1px solid #000000;
  border-radius: 4px;
  color: white;
  padding: 13px;
  width: 134px;
  margin-top: 30px;
}
.host-profile-view-information-event-box-information {
  padding: 64px;
}
.host-profile-view-information-event-box-information-space > div {
  width: 200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.host-profile-view-information-event-box-information-space {
  /* display: flex; */
  /* grid-template-columns: auto auto; */
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

.single-payment-history-pop-show-wrapper {
  position: absolute;
  background: #00000044;
  top: 11rem;
  /* left: 18.5rem; */
  /* left: 0; */
  right: 0;
  bottom: 0;
  /* width: calc(100vw - 15.5vw); */
  width: 84.5vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  /* justify-content: center; */
}

.single-payment-history-pop-show-card {
  background: white;
  padding: 32px;
  width: 455px;
}

.single-payment-history-pop-card-heading img {
  width: 179px;
  height: 110px;
}
.single-payment-history-pop-card-heading {
  display: flex;
  align-items: flex-start;
  gap: 22px;
  justify-content: space-around;
  padding-bottom: 30px;
  border-bottom: 1px solid #cccccc;
}

.single-payment-history-pop-card-guest-info img {
  height: 80px;
  width: 80px;
  border-radius: 100%;
}
.single-payment-history-pop-card-guest-info h4 {
  margin-right: 60px;
}
.single-payment-history-pop-card-guest-info {
  display: flex;
  align-items: center;
  gap: 22px;
  /* justify-content: space-around; */
  padding-bottom: 30px;
  border-bottom: 1px solid #cccccc;
  padding-top: 19px;
}
.single-payment-history-pop-reservation-date {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  margin-bottom: 8px;
}
.single-payment-history-pop-reservation-date-time {
  border-bottom: 1px solid #cccccc;
  padding-bottom: 30px;
  margin-top: 20px;
}
.single-payment-history-pop-reservation-time {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
}
.single-payment-history-pop-activity {
  border-bottom: 1px solid #cccccc;
  padding-bottom: 30px;
  margin-top: 20px;
}
.single-payment-history-pop-attedies {
  border-bottom: 1px solid #cccccc;
  padding-bottom: 30px;
  margin-top: 20px;
}
.single-payment-history-pop-earning {
  margin-top: 20px;
  /* border-bottom: 1px solid #CCCCCC; */
}

.single-payment-history-pop-earning-calculate {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
}
.single-payment-history-pop-earning-total {
  font-weight: 600;
  color: #000000;
}

.reservation-pagination-button-group button {
  border: 1px solid black;
  border-radius: 5px;
  padding: 10px;
}
.reservation-pagination-button-group {
  display: flex;
  /* width: 57%; */
  gap: 5px;
  justify-content: center;
  margin-bottom: 20px;
  margin: 10px;
  padding-right: 20px;
}

.reservation-list-header {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  width: 55%;
  margin-top: 30px;
}

.DownOutlined_icon svg {
  font-size: 18px !important;
}

.upload_icon_antd svg {
  font-size: 25px !important;
}

.info_icon_antd svg {
  font-size: 38px !important;
}

/* Editing here becuase inline css edit is not working at component */
.copy-link-host-profile svg {
  font-size: 1.5em !important;
  margin-right: 4px;
}

/* Editing here becuase inline css edit is not working at component */
.warning-outline-error-boundary svg {
  font-size: 1.5em !important;
  margin-right: 4px;
  color: #faad14;
}

/* CSS for BasicInformationInsert.js file (City select)*/
#basicsCitySelect
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  background-color: #f3f3f3 !important;
  border: 1px solid #f3f3f3 !important;
  height: 43px !important;
}

/* CSS for BasicInformationInsert.js file (City select)*/
#basicsCitySelect
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item,
#basicsCitySelect
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-placeholder {
  line-height: 40px !important;
}

/* CSS for BasicInformationInsert.js file (City select)*/
#basicsCitySelect .ant-select-selection-item {
  /* to fix the alignment of the text */
  flex: none;
}

.white-spin-container .ant-spin-dot-item {
  background-color: white !important;
}

/* for /space route => Can't submit for review error modal */
.ant-modal-confirm-error .ant-modal-confirm-body > .anticon svg {
  height: 20px;
  width: 20px;
  margin-right: 2px;
}
