.switch-left-right {
  width: 102px;
  height: 36px;
  border: 0.5px solid #e8e8e8;
  border-radius: 30px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* overflow: hidden; */
}
.switch {
  cursor: pointer;
}

.switch-left-right-close::after {
  content: 'OFF';
  margin: 7px;
}
.switch-left-right-open::before {
  content: 'OPEN';
  margin: 7px;
}

.switch-left-right-open {
  background: #008a22;
  color: white;
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
}

.switch-left-right-close {
  background: #f3f3f3;
  color: #515151;
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
  border: 0.5px solid #000000;
}

.switch-left-right input {
  display: none;
}
.switch-handle {
  background-color: #f3f3f3;
  border: 0.5px solid #000000;
  box-sizing: border-box;
  height: 35px;
  width: 35px;
  border-radius: 100%;
  margin: 0px 0px;
}
