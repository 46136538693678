.payments {
  display: flex;
  justify-content: space-around;
}
.card__each {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 20px;
}
.payment__method h1 {
  font-size: 32px;
  font-weight: 500;
  margin-top: 74px;
}
.payment__method h3 {
  font-size: 24px;
  font-weight: 500;
  margin-top: 64px;
}
.payment__method p {
  font-size: 16px;
  width: 799px;
}
.payment__policy {
  border-radius: 8px;
  border: 1px #ccc solid;
  height: 240px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 80px;
}
.payment__policy p {
  width: 379px;
  font-size: 14px;
}
.blue--text {
  font-size: 14px;
  color: blue;
  cursor: pointer;
}
.blue--text:hover {
  text-decoration: underline;
}
.top {
  border-radius: 8px 8px 0 0;
  border: none;
}
.top:hover, .top:focus {
  position: relative;
  z-index: 2;
}
.exp {
  border-radius: 0 0 0 8px;
  width: 50%;
}
.exp:hover, .exp:focus {
  position: relative;
  z-index: 2;
}
.cvv {
  border-radius: 0 0 8px 0;
  width: 50%;
}
.cvv:hover, .cvv:focus {
  position: relative;
  z-index: 2;
}
.bottom {
  width: 616px;
  display: flex;
  align-items: flex-end;
  outline: none;
}
.bottom:hover, .bottom:focus {
  position: relative;
  z-index: 2;
}
.zip {
  border-radius: 8px;
  margin-top: 10px;
}
.input-card-number {
  width: 616px;
  height: 56px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  text-indent: 15px;
  font-size: 16px;
  transition: 0.2s ease-out all;
}
.input-card-number:hover {
  outline: #6568e6 1px solid;
}
.input-card-number:focus {
  outline: #6568e6 1px solid;
  box-shadow: 0px 0px 5px #6568e6;
}
.card-list {
  border-bottom: #ccc 1px solid;
  margin: 30px 0;
}
.card-list h1 {
  font-size: 16px;
  font-weight: 500;
}
