.top__header {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 32px;
}
li {
  list-style-type: none;
}
.bar:hover {
  background-color: rgba(119, 119, 119, 0.08);
  cursor: context-menu;
  box-shadow: #5e60d6 1px solid;
}
.search_ {
  height: 60px;
}
.search__bar {
  border: #ccc 1px solid;
  border-radius: 8px;
  box-shadow: rgba(204, 204, 204, 0.3) 2px 2px 8px;
  padding: 8px;
  transition: cubic-bezier(0.42, 0, 0.86, -0.24) 0.3s all;
}
.specific__property {
  display: flex;
  /* margin: 8px; */
  border-radius: 8px;
  overflow: hidden;
  align-items: center;
  border-bottom: #ccc 2px solid;
  padding-bottom: 20;
}
.img__arrow-btns {
  opacity: 0;
  z-index: 5;
  transition: linear 0.2s all;
}
.property__img {
  overflow: hidden;
}
.property__img:hover {
  opacity: 0.95;
  transition: ease-out 0.3s all;
  border-radius: 8px;
  cursor: pointer;
}
.specific__property:hover .img__arrow-btns {
  opacity: 1;
}
.top-bar-input,
.top-bar-input:focus {
  border: none;
  outline: none;
  font-size: 15px;
  text-overflow: ellipsis;
  margin-top: -5px;
}
.search__bar__in {
  display: flex;
  padding: 15px;
  align-items: center;
  border: #ccc 1px solid;
  border-radius: 8px;
  box-shadow: rgba(204, 204, 204, 0.3) 2px 2px 8px;
  height: 73px;
  transition: cubic-bezier(0.42, 0, 0.86, -0.24) 0.3s all;
  position: relative;
}
.search__btnn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #6568e6;
  border-radius: 6px;
  width: 110px;
  height: 44px;
  margin-left: -20px;
}
.search__btnn:hover {
  background-color: #5e60d6;
}
.search__btnn h5 {
  color: #fff;
  margin: 12px;
  font-size: 16px;
  cursor: context-menu;
}
.in__btns {
  /* padding: 20px; */
  padding-inline: 20px;
  /* height: 70px; */
  /* transition: ease-out 0.3s all;
  transition: -webkit-ease-in-out 0.3s all; */
  /* margin-left: 10px; */
  width: 230px;
}
.in__btns:hover {
  cursor: pointer;
  /* background-color: rgba(119, 119, 119, 0.2); */
  background: radial-gradient(30deg, rgba(119, 119, 119, 0.09), #fff);
}
.in__btns h5 {
  color: #777;
  user-select: none;
}
/* .in__btns h5:hover {
  color: #000;
  user-select: none;
} */
.in__btns:nth-child(1) {
  border-radius: 8px 0px 0px 8px;
  /* width: 220px; */
  overflow: hidden;
}
.in__btns:nth-last-child(1) {
  border-radius: 0px 8px 8px 0px;
}
.label-start-end-date2 {
  font-size: 14px;
  font-weight: 500;
}
.time-text2 {
  font-size: 14px;
  padding: 10px;
  font-weight: 500;
  padding-inline: 24px;
  margin-left: -47px;
  transition: 0.1s ease-out all;
}
.time-text2:hover {
  font-weight: 500;
  background-color: #dcdcdc;
}
.time-text2-focus {
  font-size: 14px;
  font-weight: 500;
  padding: 10px;
  padding-inline: 24px;
  margin-left: -47px;
  background-color: #dcdcdc;
}
.date-menu-common {
  position: absolute;
  z-index: 1;
  background-color: #fff;
  box-shadow: #b3b3b365 1px 1px 10px;
  border-radius: 0px 0px 10px 10px;
  border-radius: 5px;
  margin-top: 55px;
  display: flex;
  flex-direction: column;
  text-align: left;
  text-align: center;
  border: #ccc 1px solid;
  overflow-y: scroll;
  transition: ease-in-out 0.2s all;
}
.date-menu-calendar-menu {
  margin-left: -10px;
  height: 250px;
  width: 120px;
}
.date-menu2 {
  height: 290px;
  width: 140px;
}
.search__bar:hover {
  background-color: rgba(204, 204, 204, 0.15);
  transition: ease-in-out 0.3s all;
}
.search__bar__child {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.search__bar__child h5 {
  color: rgb(126, 126, 126);
  margin: 8px;
  user-select: none;
}
.search__bar__child img {
  margin-inline: 8px;
}
.right__side__links {
  display: flex;
}
.link_text {
  margin-top: 7px;
  font-size: 16px;
  margin-inline: 8px;
  cursor: pointer;
  color: #000;
  font-family: 'Inter';
  font-weight: 500;
}
.link_text:hover {
  text-decoration: underline;
}
.room-label {
  font-weight: 500;
  font-size: 14px;
  font-family: 'Inter';
  font-weight: 400;
  color: #000;
  margin-top: 5px;
  width: 200px;
}
.map-popup {
  position: absolute;
  width: 200px;
  /* height: 100px; */
  background-color: #fff;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.07) 2px 2px 10px;
  top: -290px;
  z-index: 12;
  overflow: hidden;
  text-overflow: ellipsis;
}
.map-popup-none {
  display: none;
}
/* .search-bar {
  position: fixed;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
} */
.search-bar2 {
  display: flex;
  justify-content: center;
  align-items: center;
  outline: #cccccc 1px solid;
  padding: 10px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.07) 2px 2px 10px;
  font-family: 'Inter', sans-serif;
}
.search-bar2 h3 {
  font-size: 14px;
  margin: 0 15px;
  cursor: context-menu;
  user-select: none;
}

.search-location,
.search-location:hover,
.search-location:focus {
  border: none;
  outline: none;
}
.search-activity,
.search-activity:hover,
.search-activity:focus {
  border: none;
  outline: none;
}
.option-bar {
  display: flex;
  margin-top: 50px;
  justify-content: center;
  align-items: center;
}
.bar {
  display: flex;
  align-items: center;
  border: #d9d9d9 1px solid;
  border-radius: 8px;
  margin: 12px;
  transition: ease-out 0.2s all;
  user-select: none;
}
.bar h4 {
  font-size: 16px;
  margin: 10px;
  color: #656369;
}
.bar img {
  margin: 10px;
}
.search-btn:hover {
  opacity: 90%;
}

.location-menu {
  margin-top: 30px;
  background-color: #fff;
  position: absolute;
  border-radius: 10px;
  border: #cccccc 1px solid;
  box-shadow: rgba(0, 0, 0, 0.07) 2px 2px 10px;
  font-family: 'Inter', sans-serif;
  z-index: 3;
}
.menu {
  margin-top: 20px;
  background-color: #fff;
  position: absolute;
  border-radius: 10px;
  border: #cccccc 1px solid;
  box-shadow: rgba(0, 0, 0, 0.07) 2px 2px 10px;
  font-family: 'Inter', sans-serif;
  z-index: 3;
}

.txt {
  font-size: 15px;
  opacity: 0.6;
  cursor: context-menu;
  padding: 10px;
}
.txt:hover {
  background-color: #ebebeb;
}
.all-act-txt:hover {
  color: #6568e6;
  text-decoration: underline;
  cursor: context-menu;
}

.activity-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}
.backdrop {
  background-color: #000;
  width: 100vw;
  height: 100vh;
  opacity: 0.5;
  z-index: 10;
}
.mainbg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  z-index: 11;
  margin-top: -100px;
}
.main-modal {
  position: absolute;
  margin-top: 50vh;
  left: 50%;
  transform: translate(-50%, 0);
  margin: 300px;
}
.close-icon:hover {
  opacity: 0.6;
  cursor: pointer;
}
.inside-text {
  color: #6568e6;
  margin-left: 70px;
}
.no-result-text {
  text-align: center;
}
.desc {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 16px;
  margin-top: -10px;
}
.icon-infos {
  display: flex;
  margin-left: -10px;
}
.icon-infos-together {
  display: flex;
  align-items: center;
  margin: 10px;
}
.apply-btn:hover {
  opacity: 0.5;
}
