.header-bar {
  display: flex;
  justify-content: space-between;
  padding: 14px 30px;
  align-items: center;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.09);
}
.header-left-widget {
  display: flex;
  align-items: center;
}
.navTexts:hover {
  text-decoration: underline;
}
.header-left-widget:hover {
  opacity: 0.8;
}
.main-logo {
  display: flex;
  align-items: center;
}
.title {
  font-size: 20px;
  font-weight: 400;
  color: #000;
  margin: 8px;
}
.back-title {
  font-size: 16px;
  color: #000;
  margin: 8px;
}
.details {
  display: flex;
  align-items: center;
  margin: 30px 0px;
}
.deviderDiv {
  display: flex;
  justify-content: space-between;
  margin-inline: 80px;
}
.booking-input {
  border: none;
  padding: 16px;
  width: 450px;
  font-size: 15px;
  outline: #ccc 1px solid;
  border-radius: 8px;
  margin-top: 8px;
  transition: 0.2s ease-out all;
}
.booking-input:hover {
  outline: #6568E6 1px solid;
}
.booking-input:focus {
  outline: #6568E6 1px solid;
  box-shadow: 0px 0px 5px #6568E6;
}
.cards {
  display: flex;
  margin-left: -380px;
  margin-top: 2px;
}
.btn-card {
  font-size: 16px;
  border: #ccc 1px solid;
  padding: 10px 45px;
  border-radius: 5px;
}
.btn-card:hover {
  background-color: rgba(101, 104, 230, 0.5);
}
.cardBox {
  outline: rgb(112, 112, 112) 1px solid;
  border-radius: 5px;
  width: 160px;
  padding: 9px;
  text-align: center;
  margin: 5px 5px;
  display: flex;
  align-items: center;
}
.cardBox-btn {
  border-radius: 5px;
  width: 160px;
  padding: 9px;
  text-align: center;
  margin: 5px 5px;
  display: flex;
  align-items: center;
}
.cardBox-btn:hover {
  background-color: rgba(242, 242, 242, 0.5);
}
.card-box-input,
.card-box-input:focus-visible,
.card-box-input:focus {
  outline: rgb(167, 167, 167) 1px solid !important;
  padding: 14px;
  border: none !important;
  border-radius: 5px 5px 0px 0px;
  width: 400px;
}
input[type='checkbox'] {
  accent-color: #000;
}
