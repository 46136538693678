
.side-bar {
    width: 14%;
    min-height: 100% !important;
    height: 100%;
    background-color: #EDEDED;
    
}
.dashboard-content{
    width: 86%;
}
/* .side-bar-navigation-link {}
.side-bar-navigation-link-list {} */
.side-bar-navigation-link-item {
    padding: 10px !important;
    margin-top: 15px !important;
    margin-bottom: 15px !important;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    /* border-bottom: 1px solid #BABABA; */
}

.dashboard-button-list {
    display: flex;
    align-content: center;
    justify-content: space-around;
    align-items: center;
    width: 65%;
}

.dashboard-resarvation-data-box {
    width: 70%;
    height: 175px;
    background: #cfcfcf;
    box-sizing: border-box;
    border-radius: 3px;
    border: 0.15px solid rgba(168,152,152,0.71);
    box-shadow: 3px 8px 8px rgba(168,152,152,0.71);
    margin-top: 10px;
    margin-left: 5px;
}