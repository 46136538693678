.statusBox {
    width: 100% !important;
    height: 151px;
    background: #E6F0FF;
}
.status-progress-bar {
    height: 5px !important;
    border-radius: none;
}
.status-bar-button {
    background-color: white !important;
}

.status-bar-button:hover {
    background-color: black !important;
}
.statusBox-contant-area {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    height: 151px !important;
}
.statusBox-contant {
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: flex-start;
}
.statusBox-button {
    width: 30%;
    display: flex;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
}