.space-side-bar-navigation-link-list {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  align-items: flex-start;
}
.space-side-bar-navigation-link-item {
  width: 100%;
  border-bottom: 1px solid lightgray;
}
.space-side-bar-navigation-link {
  padding: 15px 0px 20px 35px;
}

.rotate-right {
  transform: rotateZ(272deg);
}
.hide-elements {
  display: none !important;
}
.child-ul {
  float: none;
}
.arrow-down {
  float: right;
  margin-left: 10px;
  margin-top: 3px;
}

.space-list-child li {
  padding: 15px 0px 0px 16px !important;
  border-bottom: none !important;
}
.space-list-child a {
  color: #000;
}
.arrow-image-space-list {
  margin-left: 2px;
}

.space-setup-inserte-section {
  /* margin-left: 34px; */
  /* margin-top: 49px; */
  padding: 50px;
}
.radio-button-group > h1 {
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
}
.radio-button-group p {
  margin-top: 17px;
  margin-bottom: 24px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}

.radion-buttons {
  display: grid;
  grid-template-columns: auto auto auto;
  /* gap: 0.1rem; */
  gap: 3.5rem;
  width: 70%;
  /* height: 260px; */
  margin-left: 28px;
  margin-top: 40px;
  margin-bottom: 40px;
}
.radio-button label {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  margin-left: 26px;
  cursor: pointer;
}
.radio-button {
  position: relative;
  display: flex;
  align-content: center;
  align-items: center;
}
.radio-button input {
  position: absolute;
  /* opacity: 0; */
  cursor: pointer;
}

.radio-button .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: #eee;
  border-radius: 50%;
  border: 1px solid black;
}

/* On mouse-over, add a grey background color */
.radio-button:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.radio-button input:checked ~ .checkmark {
  background-color: #ccc;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-button input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radio-button .checkmark:after {
  top: 1.5px;
  left: 1.5px;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background: black;
}
.number-of-guest h1 {
  width: 587px;
  height: 58px;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  align-items: center;
  color: #000000;
}
.number-of-guest > .number-of-guest-input-fielt-group {
  margin-top: 48px;
  position: relative;
  width: 324px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.point-of-contact-field-group input {
  margin: 15px 0px 38px 0px;
  width: 468px;
  height: 43px;
  left: 299px;
  top: 1468px;
  border: none;
  background: #f3f3f3;
  padding: 10px;
}
.point-of-contact-field-group > label {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  width: 513px;
  height: 22px;
}
.point-of-contact-field-group > .note {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #b4b4b4;
}
.number-of-guest > .number-of-guest-input-fielt-group label {
  position: relative;
  width: 149px;
  height: 22px;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  align-items: center;
  color: #000000;
}
.number-of-guest > .number-of-guest-input-fielt-group input {
  width: 136px;
  height: 43px;
  background: #f3f3f3;
  border: none;
  margin-left: 37px;
  padding-left: 12px;
}
.poligon {
  width: 13.14px;
  height: 11.38px;
  position: absolute;
  right: 8px;
  cursor: pointer;
}
.poligon-down {
  bottom: 8px;
}
.poligon-up {
  top: 8px;
}

.allowed-age-guest h1 {
  width: 587px;
  height: 44px;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
}
.allowed-age-guest p {
  width: 518px;
  height: 21px;
  font-weight: 500;
  font-size: 17px;
  line-height: 21px;
  color: #000000;
}
.allowed-age-input-fielt-group {
  position: relative;
  width: 324px;
  margin-top: 30px;
  border: none;
}
.allowed-age-input-fielt-group
  > .allowed-age-input-fielt-group-drop-down-poligon {
  position: absolute;
  right: 12px;
  bottom: 15px;
  cursor: pointer;
}
.allowed-age-input-fielt-group input {
  width: 324px;
  height: 43px;
  background: #f3f3f3;
  border: none;
  /* margin-top: 30px; */
}

.allowed-age-input-fielt-group-drop-down-box {
  position: absolute;
  width: 182px;
  background: #ffffff;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 4px;
  right: 0;
  top: 50px;
  z-index: 1000;
}
.allowed-age-input-fielt-group-drop-down-box ul {
  list-style: none;
  margin: 0;
  padding: 7.5px 19px 7.5px 19px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.allowed-age-input-fielt-group-drop-down-box ul li {
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: 1fr 2fr;
  justify-content: center;
  align-items: center;
  justify-items: start;
  /* padding-left: 19px; */
  padding-top: 7.5px;
  padding-bottom: 7.5px;
}
.allowed-age-input-fielt-group-drop-down-box ul li > label {
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  width: 100px;
  flex-direction: row;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.radio-box-dummy div {
  background: black;
  height: 9px;
  width: 9px;
  border-radius: 100%;
}
.radio-box-dummy {
  height: 15px;
  width: 15px;
  padding: 0px;
  background-color: white;
  border-radius: 100%;
  border: 1px solid #000000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.allowed-age-input-fielt-group-drop-down-box ul li > input {
  width: 14px;
  height: 14px;
  margin-top: 0px;
}

#allowed_age {
  padding: 12px;
  cursor: pointer;
}

.unavailable {
  color: #707070;
  /* width: 367px; */
}

.add-space-category-button-group {
  margin-top: 10px;
}
.add-space-category-button-group > input {
  width: 160px;
  margin-right: 5px;
  padding: 5px 10px;
  border-radius: 4px;
}
.add-space-category-button-group > button {
  width: 60px;
  padding: 5px 10px;
  border: 1px solid black;
}
