.about-space-insert-form {}
.about-space-insert-form hr{
    margin-bottom: 37px;
    width: 513px;
    border: 1px solid #BABABA;
    margin-top: 56px;
}
.addOptOutActivities input{
    width: 155px !important;
    height: 41px !important;
    float: left;
}
.addOptOutActivities button{
    width: 63px !important;
    height: 41px !important;
    margin-left: 10px;
    float: right;
    background-color: #E6F0FF !important;
    color: black !important;
    border: 1px solid black !important;
}
.about-space-insert-form hr:last-child{
    margin-bottom: 191px;
    margin-top: 125px;
}

.about-space-insert-textarea-group {
    color: black;
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    align-items: stretch;
    row-gap: 22px;
    /* padding-bottom: 44px;
    border-bottom: 1px solid lightgray;
    margin-bottom: 44px; */
    width: 535px;
}
.about-space-insert-textarea-group h1{
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
}
.about-space-insert-textarea-group p{
    font-weight: 500;
    font-size: 17px;
    line-height: 21px;
}
.about-space-insert-textarea-group small{
    font-size: 14px;
    line-height: 17px;
    margin-top: -15px;
}
.about-space-insert-textarea-group textarea{
    background-color: #F3F3F3;
    width: 468px;
    height: 108px;
    border: none;
    padding: 10px;
}

.about-space-insert-input-number-group {
    position: relative;
}
.about-space-insert-input-number-group h1{
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
}
.about-space-insert-input-number-group input,
.about-space-insert-input-number-group button{
    margin-top: 37px;
    width: 201px;
    height: 41px;
    background: #FFFFFF;
    border: 0.5px solid lightgray;
    box-sizing: border-box;
    border-radius: 3px;
}

.about-space-insert-input-number-group button{
    position: absolute;
    width: 67px;
    left: 134px;
    color: black;
}
.about-space-insert-input-number-group p{
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    margin-top: 18px;
}
.about-space-insert-button-group button{
    background: #6568E6;
    margin-right: 172px;
    color: white;

    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 4px;
    width: 180px;
    height: 48px;
}

.about-space-insert-button-group button:first-child{
    /* background: white; */
    margin-right: 172px;
}
.basic-information-input-group {}

.show-picture-information-wrapper {
    background-color: #00000025;
    position: absolute;
    top: 286px;
    left: 317px;
    width: 84.5%;
    height: 164vw;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: flex-start;
}
.show-picture-information span{
    width: 32px;
    height: 17px;
    font-size: 14px;
    line-height: 17px;
    color: #0E1111;
}
.show-picture-information hr{
    width: 442px !important;
    height: 0px !important;
    border: 1px solid #BABABA !important;
}
.show-picture-information textarea{
    width: 325px;
    height: 108px;
    background: #F3F3F3;
    border: none;
    padding: 10PX;
}
.show-picture-information label{
    width: 143px;
    height: 21px;
    font-weight: 500;
    font-size: 17px;
    line-height: 21px;
    align-items: center;
    color: #000000;
}
.show-picture-information{
    margin-top: 25vw;
    background-color: white;
    width: 40vw;
    height: 30vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 56px 0px 49px 99px;
    gap: 10px;
}

.picture {
    width: 316px;
    height: 178px;
    /* background-color: rgb(131, 179, 195); */
    border: 0.893344px solid #CCCCCC;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.show-picture-informationbutton-group {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    gap: 6vw;
}
.show-picture-informationbutton-group div:first-child {
    background-color: white;
}
.show-picture-informationbutton-group div {
    width: 160px;
    height: 44px;
    background: #E6F0FE;
    border: 0.5px solid #000000;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}